body {
	color: #1a191e;
	background-color: #fff;
	font-family: Inter, sans-serif;
	font-weight: 400;
	line-height: 160%;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.main-wrapper {
	width: 100%;
}
.footer {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 1px solid #e8e8e8;
}
.button {
	display: block;
	text-align: center;
	// border: 2px solid #e8e8e8;
	border-radius: 8px;
	padding: 1rem 1.5rem;
	font-family: DM Sans, sans-serif;
	font-size: 1.125rem;
	font-weight: 500;
	text-decoration: none;
	transition: all 0.2s;
	.button-inner {
		z-index: 10;
		flex-direction: column;
		height: 1.5rem;
		display: flex;
		position: relative;
		overflow: hidden;
		.button-inner-text,
		.button-inner-text-hover {
			transition: transform 0.4s ease;
			transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
				rotateZ(0deg) skew(0deg, 0deg);
			transform-style: preserve-3d;
		}
	}
	&:hover {
		.button-inner {
			.button-inner-text,
			.button-inner-text-hover {
				transition: transform 0.4s ease;
				transform: translateY(-100%);
			}
		}
	}
}
.button.primary {
	display: block;
	background-color: #5bb5a2;
	color: #fff;
	&:hover {
		transition: background-color 0.4s ease;
		background-color: #449e8b;
	}
}
.button.small {
	border-radius: 6px;
	padding: 0.5rem 1rem;
	font-size: 1rem;
}
.page-padding {
	padding-left: 2.5rem;
	padding-right: 2.5rem;
}
.background-color-primary-light {
	background-color: #e8f2ee;
}

// 一排三个图片
.limit-section {
	padding-top: 10rem;
	padding-bottom: 10rem;
	.limit-container {
		max-width: 76.25rem;
		display: flex;
		gap: 2rem;
		margin-bottom: 3rem;

		.limit-iterm {
			width: 23.625rem;
			text-align: center;
			.limit-img {
				height: 23.625rem;
				border-radius: 1rem;
				margin-bottom: 1.5rem;
				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
			.limit-title {
				font-family: DM Sans, DM Sans;
				font-weight: 500;
				font-size: 22px;
				line-height: 26px;
				margin-bottom: 14px;
			}
			.limit-des {
				color: #545454;
				font-family: Inter, Inter;
				font-weight: 500;
				font-size: 16px;
				line-height: 26px;
			}
		}
	}
}
// 左右结构banner
.banner-section {
	width: 100%;
	padding-top: 8rem;
	padding-bottom: 8rem;
	overflow: hidden;
	.banner-container {
		display: flex;
		margin: auto;
		width: 100%;
		max-width: 75rem;
		align-items: center;
		justify-content: space-between;
		.banner-content {
			font-weight: 500;
			max-width: 30.375rem;
			.banner-title {
				font-family: DM Sans, DM Sans;
				font-size: 40px;
				line-height: 48px;
				margin-bottom: 1rem;
			}
			.banner-des {
				font-family: Inter, Inter;
				color: #666666;
				font-size: 16px;
				line-height: 26px;
				margin-bottom: 4rem;
			}
			.button {
				width: max-content;
			}
		}
		.banner-img {
			img {
				max-width: 100%;
				object-fit: contain;
			}
		}
	}
	.support-hero-grid {
		grid-column-gap: 4rem;
		grid-row-gap: 4rem;
		grid-template-rows: auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		display: grid;
		.hero-cards-wrapper {
			margin-top: -2rem;
			margin-bottom: -14rem;
			justify-self: center;
			.headImg {
				width: 280px;
				position: relative;
				img {
					width: 100%;
				}
				.cord {
					z-index: 5;
					position: relative;
				}
				.premiumImg {
					z-index: 15;
				}
				.freeImg {
					transform-origin: 0 100%;
					position: absolute;
					top: 20%;
					bottom: auto;
					left: 0%;
					right: 0%;
					animation: freeImg 0.5s ease none;
					transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
						rotateZ(-14deg) skew(0deg, 0deg);
					transform-style: preserve-3d;
				}
				.goldImg {
					z-index: 10;
					transform-origin: 100% 100%;
					position: absolute;
					top: 20%;
					bottom: auto;
					left: 0%;
					right: 0%;
					animation: goldImg 0.5s ease none;
					transform-style: preserve-3d;
					transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
						rotateZ(14deg) skew(0deg, 0deg);
				}
			}
			.loaded {
				animation: loaded 0.9s ease none;
				transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
					rotateZ(0deg) skew(0deg, 0deg);
				transform-style: preserve-3d;
			}
		}
	}
}
// 左右结构介绍
.about-section {
	width: 100%;
	max-width: 87.5rem;
	padding-top: 10.5rem;
	padding-bottom: 3.625rem;
	margin: auto;
	.about-item {
		width: 100%;
		max-width: 1200px;
		margin: 0 auto 12.5rem auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		&:nth-last-child(1) {
			margin-bottom: 0;
		}
		.about-item-content {
			width: 34.1875rem;
			text-align: start;
			.about-item-title {
				font-family: DM Sans, DM Sans;
				font-weight: 500;
				font-size: 28px;
				line-height: 34px;
				margin-bottom: 2rem;
			}
			.about-item-des {
				font-family: Inter, Inter;
				font-weight: 500;
				font-size: 18px;
				color: #666666;
				line-height: 2rem;
			}
		}
		.about-item-img {
			width: 31.125rem;
			img {
				width: 100%;
				object-fit: contain;
			}
		}
	}
}
.legal {
	width: 100%;
	max-width: 1200px;
	text-align: left;
	overflow-wrap: break-word;
	p {
		width: 100%;
		max-width: 1200px;
		margin: 1rem 0;
	}
	ul {
		padding-left: 40px;
	}
	li {
		width: 100%;
		max-width: 1200px;
		margin: 1rem 0;
	}
	h1 {
		width: 100%;
		max-width: 1200px;
		margin-top: 2rem;
	}
}
@media screen and (max-width: 991px) {
	.page-padding {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
	.limit-section {
		padding-top: 4rem;
		padding-bottom: 4rem;
		.limit-container {
			flex-direction: column;
			.limit-iterm {
				max-width: 100%;
				width: 100%;
			}
		}
	}
	.banner-section {
		padding-top: 89px;
		padding-bottom: 89px;
		.banner-container {
			flex-direction: column;
			.banner-content {
				text-align: center;
				padding: 4rem;
				padding-top: 0;
				.banner-des {
					margin-bottom: 20px;
				}
				.button {
					margin: 0 auto;
				}
			}
			.banner-img {
				max-width: 100%;
			}
		}
		.banner-container.support-hero-grid {
			// display: flex;
			text-align: center;
			grid-template-columns: 1fr;
			grid-column-gap: 2rem;
			grid-row-gap: 2rem;
			.banner-content {
				padding: 1.5rem;
			}
			.hero-cards-wrapper {
				margin-top: 1rem;
				margin-bottom: -10rem;
				.headImg {
					width: 160px;
					.goldImg,
					.freeImg {
						top: 12%;
					}
				}
			}
		}
	}
	.about-section {
		padding-top: 89px;
		padding-bottom: 89px;
		.about-item {
			flex-direction: column-reverse;
			.about-item-content {
				max-width: 100%;
				text-align: center;
				margin-top: 16px;
			}
			.about-item-img {
				width: 100%;
			}
		}
	}
	.lawsContent {
		.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
			justify-content: center;
		}
	}
	.footer {
		.footer-services {
			padding: 4rem 1.5rem;
			flex-direction: column;
			display: flex;
		}
		.footer-bottom {
			flex-direction: column;
		}
	}
}
@keyframes freeImg {
	from {
		transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0)
			skew(0deg, 0deg);
	}
	to {
		transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
			rotateZ(-14deg) skew(0deg, 0deg);
	}
}
@keyframes goldImg {
	from {
		transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0)
			skew(0deg, 0deg);
	}
	to {
		transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(14deg)
			skew(0deg, 0deg);
	}
}
@keyframes loaded {
	from {
		transform: translateY(100%);
	}
	to {
		transform: translateY(0%);
	}
}
