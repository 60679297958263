.crossBorder {
	.banner-section {
		max-height: 400px;
	}
	.crossBorderSolution {
		width: 1200px;
		margin: 0 auto;
		margin-top: 160px;
		display: flex;
		justify-content: space-between;
		.crossBorderSolutionText {
			max-width: 587px;
			margin: 19px 0 0;
			.crossBorderSolutionTitle {
				font-family: DM Sans, DM Sans;
				font-weight: 500;
				font-size: 28px;
				color: #1a191e;
				line-height: 34px;
				text-align: left;
				font-style: normal;
				text-transform: none;
				margin-bottom: 32px;
			}
			.crossBorderSolutionDesc {
				font-family: Inter, Inter;
				font-weight: 500;
				font-size: 18px;
				color: #666666;
				line-height: 32px;
				text-align: left;
				font-style: normal;
				text-transform: none;
				p {
					margin: 0;
					margin-bottom: 19px;
				}
			}
		}
		.crossBorderSolutionImg {
			width: 450px;
			height: 297px;
			background-image: url(../../asserts/images/soluphone.png);
			background-size: contain;
			background-repeat: no-repeat;
		}
	}
	.crossBorderTools {
		width: 1200px;
		margin: 0 auto;
		margin-top: 160px;
		display: flex;
		justify-content: space-between;
		.crossBorderToolsDetails {
			.crossBorderToolsDetailsTitle {
				font-family: DM Sans, DM Sans;
				font-weight: 500;
				font-size: 20px;
				color: #1a191e;
				line-height: 24px;
				text-align: left;
				font-style: normal;
				text-transform: none;
				margin-bottom: 8px;
			}
			.crossBorderToolsDetailsDesc {
				font-family: DM Sans, DM Sans;
				font-weight: 500;
				font-size: 28px;
				color: #1a191e;
				line-height: 34px;
				text-align: left;
				font-style: normal;
				text-transform: none;
			}
			.crossBorderToolsDetailsImg {
				width: 588px;
				height: 318px;
				background-image: url(../../asserts/images/paybank.png);
				background-size: contain;
				background-repeat: no-repeat;
				background-position: left;
			}
		}
		.crossBorderToolsTag {
			margin-top: 138px;
			.crossBorderToolsTagBox {
				display: flex;
				gap: 16px;
				margin-bottom: 20px;
				.crossBorderToolsTagBoxIcon {
					width: 40px;
					height: 40px;
					background-image: url(../../asserts/images/hook.png);
					background-size: contain;
					background-repeat: no-repeat;
				}
				.crossBorderToolsTagBoxText {
					font-family: Inter, Inter;
					font-weight: 500;
					font-size: 18px;
					color: #666666;
					line-height: 32px;
					text-align: left;
					font-style: normal;
					text-transform: none;
				}
			}
		}
	}
	.crossBorderSecurity {
		width: 1200px;
		margin: 0 auto;
		margin-top: 160px;
		display: flex;
		justify-content: space-between;
		.crossBorderSecurityDesc {
			.crossBorderSecurityDescTitle {
				font-family: DM Sans, DM Sans;
				font-weight: 500;
				font-size: 28px;
				color: #1a191e;
				line-height: 34px;
				text-align: left;
				font-style: normal;
				text-transform: none;
			}
			.crossBorderSecurityDescText {
				max-width: 587px;
				font-family: Inter, Inter;
				font-weight: 500;
				font-size: 18px;
				color: #666666;
				line-height: 32px;
				text-align: left;
				font-style: normal;
				text-transform: none;
			}
		}
		.crossBorderSecurityImg {
			width: 450px;
			height: 297px;
			background-image: url(../../asserts/images/cashier.png);
			background-size: contain;
			background-repeat: no-repeat;
		}
	}
	.question {
		width: 100%;
		margin-top: 276px;
		margin-bottom: 96px;
	}
}
@media screen and (max-width: 991px) {
	.crossBorder {
		.banner-section {
			max-height: 600px;
		}
		.crossBorderSolution {
			width: 100%;
			margin: 1rem auto;
			display: block;
			justify-content: space-between;
			.crossBorderSolutionText {
				max-width: 587px;
				margin: 19px 0 0;
				.crossBorderSolutionTitle {
					font-family: DM Sans, DM Sans;
					font-weight: 500;
					font-size: 28px;
					color: #1a191e;
					line-height: 34px;
					text-align: left;
					font-style: normal;
					text-transform: none;
					margin-bottom: 32px;
				}
				.crossBorderSolutionDesc {
					font-family: Inter, Inter;
					font-weight: 500;
					font-size: 18px;
					color: #666666;
					line-height: 32px;
					text-align: left;
					font-style: normal;
					text-transform: none;
					p {
						margin: 0;
						margin-bottom: 19px;
					}
				}
			}
			.crossBorderSolutionImg {
				margin: 1rem auto;
				width: 380px;
				height: 297px;
				background-image: url(../../asserts/images/soluphone.png);
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
		.crossBorderTools {
			width: 100%;
			margin: 0 auto;
			margin-top: 160px;
			display: block;
			justify-content: space-between;
			.crossBorderToolsDetails {
				.crossBorderToolsDetailsTitle {
					font-family: DM Sans, DM Sans;
					font-weight: 500;
					font-size: 20px;
					color: #1a191e;
					line-height: 24px;
					text-align: left;
					font-style: normal;
					text-transform: none;
					margin-bottom: 8px;
				}
				.crossBorderToolsDetailsDesc {
					font-family: DM Sans, DM Sans;
					font-weight: 500;
					font-size: 28px;
					color: #1a191e;
					line-height: 34px;
					text-align: left;
					font-style: normal;
					text-transform: none;
				}
				.crossBorderToolsDetailsImg {
					width: 380px;
					height: 318px;
					margin: 1rem auto;
					background-image: url(../../asserts/images/paybank.png);
					background-size: contain;
					background-repeat: no-repeat;
					background-position: left;
				}
			}
			.crossBorderToolsTag {
				margin-top: 138px;
				margin: 1rem auto;
				.crossBorderToolsTagBox {
					display: flex;
					gap: 16px;
					margin-bottom: 20px;
					.crossBorderToolsTagBoxIcon {
						width: 40px;
						height: 40px;
						background-image: url(../../asserts/images/hook.png);
						background-size: contain;
						background-repeat: no-repeat;
					}
					.crossBorderToolsTagBoxText {
						font-family: Inter, Inter;
						font-weight: 500;
						font-size: 18px;
						color: #666666;
						line-height: 32px;
						text-align: left;
						font-style: normal;
						text-transform: none;
					}
				}
			}
		}
		.crossBorderSecurity {
			width: 100%;
			margin: 0 auto;
			margin-top: 160px;
			display: block;
			justify-content: space-between;
			.crossBorderSecurityDesc {
				.crossBorderSecurityDescTitle {
					font-family: DM Sans, DM Sans;
					font-weight: 500;
					font-size: 28px;
					color: #1a191e;
					line-height: 34px;
					text-align: left;
					font-style: normal;
					text-transform: none;
				}
				.crossBorderSecurityDescText {
					max-width: 587px;
					font-family: Inter, Inter;
					font-weight: 500;
					font-size: 18px;
					color: #666666;
					line-height: 32px;
					text-align: left;
					font-style: normal;
					text-transform: none;
				}
			}
			.crossBorderSecurityImg {
				margin: 1rem auto;
				width: 380px;
				height: 297px;
				background-image: url(../../asserts/images/cashier.png);
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
		.question {
			width: 100%;
			margin-top: 276px;
			margin-bottom: 96px;
		}
	}
}
