.recruitment {
	.recruitment-item {
		width: 100%;
		max-width: 1200px;
		.recruitment-item-content {
			.recruitment-item-title {
				font-family: DM Sans, DM Sans;
				font-weight: 500;
				font-size: 28px;
				color: #1a191e;
				line-height: 34px;
				text-align: left;
				font-style: normal;
				text-transform: none;
				margin: 3rem 0;
				.recruitment-item-des {
				}
			}
			.recruitment-item-des {
				margin: 3rem 0;
				.recruitment-item-des-title {
					font-family: DM Sans, DM Sans;
					font-weight: 500;
					font-size: 20px;
					color: #1a191e;
					line-height: 24px;
					text-align: left;
					font-style: normal;
					text-transform: none;
					margin: 1rem 0;
				}
				.recruitment-item-des-text {
					font-family: Inter, Inter;
					font-weight: 500;
					font-size: 18px;
					color: #666666;
					line-height: 32px;
					text-align: left;
					font-style: normal;
					text-transform: none;
					.recruitment-item-des-text-paragraph {
						margin: 1rem 0;
					}
				}
			}
		}
	}
}
