.home {
	.card {
		background-color: #e8f2ee;
		overflow: hidden;
		.bankCord {
			width: 100%;
			margin-top: 145px;
			.bankCordContent {
				display: flex;
				width: 1400px;
				margin-bottom: 128px;
				justify-content: space-evenly;
				.bankCordContentText {
					width: 45%;
					.bankCordContentTextTitle {
						width: 100%;
						margin: 1rem 0 0 2.5rem;
						font-weight: 500;
						font-family: DM Sans, DM Sans;
						text-align: left;
						p {
							min-height: 70px;
							line-height: 70px;
							font-size: 40px;
						}
					}
					.bankCordContentTextDesc {
						font-size: 1.3rem;
						font-weight: 500;
						margin-left: 2.5rem;
						width: 590px;
						margin-top: 1rem;
						text-align: left;
						font-family: Inter, Inter;
					}
					.bankCordContentTextTag {
						width: 80%;
						margin-left: 2.5rem;
						margin-top: 1rem;
						font-size: 0.9rem;
						.bankCordContentTextTagItem {
							width: 100%;
							display: flex;
							margin-bottom: 0.5rem;
							// justify-content: space-between;
							align-items: center;
							.tagLeft {
								width: 50%;
								display: flex;
								align-items: center;
								gap: 8px;
								.tagIcon {
									width: 1.5rem;
									height: 1.5rem;
									background-image: url('../../asserts/images/hook.png');
									background-size: 100% 100%;
								}
							}
							.tagRight {
								width: 50%;
								display: flex;
								gap: 8px;
								align-items: center;
								.tagIcon {
									width: 1.5rem;
									height: 1.5rem;
									background-image: url('../../asserts/images/hook.png');
									background-size: 100% 100%;
								}
							}
						}
					}
					.navbar-right {
						grid-column-gap: 1rem;
						grid-row-gap: 1rem;
						justify-content: flex-start;
						align-items: center;
						display: flex;
						margin-top: 64px;
						margin-left: 2.5rem;
						a {
							background-color: #5bb5a2;
							color: #fff;
							font-weight: 500;
							padding: 0.5rem 1rem;
							font-size: 1rem;
							border-radius: 6px;
							&:hover {
								background-color: #449e8b;
							}
						}
					}
				}
				.bankCordContentImg {
					width: 45%;
					.imgContent {
						width: 300px;
						height: 500px;
						position: relative;
						margin-left: 164px;
						.imgContentCord_1 {
							position: absolute;
							width: 100%;
							height: 100%;
							z-index: 15;
							img {
								width: 300px;
								height: 500px;
							}
						}
						.imgContentCord_2 {
							// position: absolute;
							// width: 100%;
							// height: 100%;
							// left: 50px;
							// bottom: 66px;
							// img {
							// 	width: 300px;
							// 	height: 500px;
							// }
							z-index: 10;
							transform-origin: 100% 100%;
							position: absolute;
							top: 6%;
							bottom: auto;
							left: 0%;
							right: 0%;
							transform-style: preserve-3d;
							transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
								rotateZ(14deg) skew(0deg, 0deg);
							img {
								width: 300px;
								height: 500px;
							}
						}
					}
				}
			}
		}
	}
	.date {
		width: 100%;
		background-color: #e8f2ee;
		.dateContent {
			width: 1400px;
			display: flex;
			justify-content: space-evenly;
			.dateContentDesc {
				width: 45%;
				margin-top: 165px;
				margin-left: 2.5rem;
				.descItem {
					margin-bottom: 56px;
					font-size: 28px;
					font-family: DM Sans, DM Sans;
					font-weight: 500;
					.descItemText {
						margin-top: 18px;
						width: 516px;
						font-family: Inter, Inter;
						font-weight: 500;
						font-size: 16px;
					}
				}
			}
			.dateContentImg {
				width: 40%;
				margin-top: 250px;
				background-image: url(../../asserts/images/dateBanner.png);
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
	}
	.phone {
		width: 100%;
		.phoneContent {
			width: 1400px;
			display: flex;
			justify-content: space-evenly;
			.phoneContentDesc {
				width: 45%;
				margin-left: 5.5rem;
				margin-top: 160px;
				.phoneContentDescTitle {
					font-family: DM Sans, DM Sans;
					font-weight: 500;
					font-size: 80px;
					margin-bottom: 64px;
				}
				.phoneContentDescItem {
					display: flex;
					gap: 32px;
					margin-bottom: 32px;
					.phoneLeft {
						width: 284px;
						height: 264px;
						border-radius: 16px 16px 16px 16px;
						border: 2px solid #e8e8e8;
						cursor: url(../../asserts/images/cursor.png), auto;
						.bill {
							width: 50px;
							height: 50px;
							background-image: url(../../asserts/images/bill.png);
							background-size: contain;
							background-repeat: no-repeat;
							margin-top: 32px;
							margin-bottom: 16px;
							margin-left: 32px;
						}
						.telephone {
							width: 50px;
							height: 50px;
							background-image: url(../../asserts/images/telephone.png);
							background-size: contain;
							background-repeat: no-repeat;
							margin-top: 32px;
							margin-bottom: 16px;
							margin-left: 32px;
						}
						.creditCard {
							width: 50px;
							height: 50px;
							background-image: url(../../asserts/images/creditCard.png);
							background-size: contain;
							background-repeat: no-repeat;
							margin-top: 32px;
							margin-bottom: 16px;
							margin-left: 32px;
						}
						.phoneTitle {
							font-family: DM Sans, DM Sans;
							font-weight: 500;
							font-size: 20px;
							margin-left: 32px;
							margin-bottom: 24px;
							width: 220px;
						}
						.phoneDesc {
							font-family: Inter, Inter;
							font-weight: 500;
							font-size: 16px;
							color: #666666;
							margin-bottom: 32px;
							width: 220px;
							margin-left: 32px;
						}
					}
					.phoneLeft:hover .bill {
						width: 55px;
						height: 55px;
					}
					.phoneLeft:hover .telephone {
						width: 55px;
						height: 55px;
					}
					.phoneLeft:hover .creditCard {
						width: 55px;
						height: 55px;
					}
					.phoneRight {
						width: 284px;
						height: 264px;
						border-radius: 16px 16px 16px 16px;
						border: 2px solid #e8e8e8;
						cursor: url(../../asserts/images/cursor.png), auto;
						.wallet {
							width: 50px;
							height: 50px;
							background-image: url(../../asserts/images/wallet.png);
							background-size: contain;
							background-repeat: no-repeat;
							margin-top: 32px;
							margin-bottom: 16px;
							margin-left: 32px;
						}
						.airplane {
							width: 50px;
							height: 50px;
							background-image: url(../../asserts/images/airplane.png);
							background-size: contain;
							background-repeat: no-repeat;
							margin-top: 32px;
							margin-bottom: 16px;
							margin-left: 32px;
						}
						.index {
							width: 50px;
							height: 50px;
							background-image: url(../../asserts/images/index.png);
							background-size: contain;
							background-repeat: no-repeat;
							margin-top: 32px;
							margin-bottom: 16px;
							margin-left: 32px;
						}
						.phoneTitle {
							font-family: DM Sans, DM Sans;
							font-weight: 500;
							font-size: 20px;
							margin-left: 32px;
							margin-bottom: 24px;
							width: 220px;
						}
						.phoneDesc {
							font-family: Inter, Inter;
							font-weight: 500;
							font-size: 16px;
							color: #666666;
							margin-bottom: 32px;
							width: 220px;
							margin-left: 32px;
						}
					}
					.phoneRight:hover .wallet {
						width: 55px;
						height: 55px;
					}
					.phoneRight:hover .airplane {
						width: 55px;
						height: 55px;
					}
					.phoneRight:hover .index {
						width: 55px;
						height: 55px;
					}
				}
			}
			.phoneContentImg {
				width: 45%;
				margin-top: 200px;
				margin-left: 124px;
				background-image: url(../../asserts/images/phone.png);
				background-size: 380px 845px;
				background-repeat: no-repeat;
			}
		}
	}
	.shop {
		width: 100%;
		margin-top: 160px;
	}
	.question {
		width: 100%;
		margin-top: 160px;
		margin-bottom: 64px;
	}
}
@media screen and (max-width: 991px) {
	.home {
		.card {
			background-color: #e8f2ee;
			overflow: hidden;
			.bankCord {
				width: 100%;
				// min-height: 22.5rem;
				margin-top: 6.525rem;
				.bankCordContent {
					display: block;
					width: 100%;
					margin-bottom: 5.76rem;
					// justify-content: space-evenly;
					.bankCordContentText {
						width: 80%;
						margin: 0 auto;
						.bankCordContentTextTitle {
							width: 100%;
							margin: 0.72rem 0 0.72rem 1.8rem;
							font-size: 2.3rem;
							font-weight: 500;
							font-family: DM Sans, DM Sans;
							// text-align: left;
							p {
								font-size: 2rem;
								line-height: 45px;
							}
						}
						.bankCordContentTextDesc {
							font-size: 0.936rem;
							font-weight: 500;
							margin-left: 1.8rem;
							width: 100%;
							text-align: left;
							font-family: Inter, Inter;
						}
						.bankCordContentTextTag {
							width: 100%;
							margin-left: 1.8rem;
							margin-top: 0.72rem;
							font-size: 0.648rem;
							.bankCordContentTextTagItem {
								width: 100%;
								display: flex;
								margin-bottom: 0.375rem;
								// justify-content: space-between;
								align-items: center;
								.tagLeft {
									width: 50%;
									display: flex;
									align-items: center;
									gap: 0.36rem;
									.tagIcon {
										width: 1.08rem;
										height: 1.08rem;
										background-image: url('../../asserts/images/hook.png');
										background-size: 100% 100%;
									}
								}
								.tagRight {
									width: 50%;
									display: flex;
									gap: 0.36rem;
									align-items: center;
									.tagIcon {
										width: 1.08rem;
										height: 1.08rem;
										background-image: url('../../asserts/images/hook.png');
										background-size: 100% 100%;
									}
								}
							}
						}
						.navbar-right {
							grid-column-gap: 0.72rem;
							grid-row-gap: 0.72rem;
							justify-content: space-around;
							align-items: center;
							display: flex;
							margin-top: 2.88rem;
							margin-left: 1.8rem;
							a {
								background-color: #5bb5a2;
								color: #fff;
								font-weight: 500;
								padding: 0.72rem 1.44rem;
								font-size: 1.44rem;
								border-radius: 0.375rem;
								&:hover {
									background-color: #449e8b;
								}
							}
						}
					}
					.bankCordContentImg {
						width: 60%;
						margin: 3rem auto;
						.imgContent {
							width: 13.5rem;
							height: 22.5rem;
							position: relative;
							margin: 0;
							// margin-left: 164px;
							.imgContentCord_1 {
								position: absolute;
								width: 100%;
								height: 100%;
								z-index: 15;
								img {
									width: 90%;
									height: 90%;
								}
							}
							.imgContentCord_2 {
								// position: absolute;
								// width: 100%;
								// height: 100%;
								// left: 50px;
								// bottom: 66px;
								// img {
								// 	width: 300px;
								// 	height: 500px;
								// }
								z-index: 10;
								transform-origin: 100% 100%;
								position: absolute;
								top: 6%;
								bottom: auto;
								left: 0%;
								right: 0%;
								transform-style: preserve-3d;
								transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
									rotateZ(14deg) skew(0deg, 0deg);
								img {
									width: 90%;
									height: 90%;
								}
							}
						}
					}
				}
			}
		}
		.date {
			width: 100%;
			background-color: #e8f2ee;
			.dateContent {
				width: 100%;
				display: block;
				// justify-content: space-evenly;
				.dateContentDesc {
					width: 60%;
					margin-top: 7.425rem;
					margin-left: 1.8rem;
					margin: 0 auto;
					.descItem {
						margin-bottom: 2.52rem;
						font-size: 1.26rem;
						font-family: DM Sans, DM Sans;
						font-weight: 500;
						.descItemText {
							margin-top: 0.81rem;
							width: 18.22rem;
							font-family: Inter, Inter;
							font-weight: 500;
							font-size: 0.72rem;
						}
					}
				}
				.dateContentImg {
					width: 60%;
					min-height: 300px;
					margin-top: 11.25rem;
					margin: 0 auto;
					background-image: url(../../asserts/images/dateBanner.png);
					background-size: contain;
					background-repeat: no-repeat;
				}
			}
		}
		.phone {
			width: 100%;
			.phoneContent {
				width: 100%;
				display: block;
				// justify-content: space-evenly;
				.phoneContentDesc {
					width: 100%;
					// margin-left: 3.96rem;
					// margin-top: 7.2rem;
					margin: 5rem auto;
					.phoneContentDescTitle {
						font-family: DM Sans, DM Sans;
						font-weight: 500;
						font-size: 2.8rem;
						margin-bottom: 2.88rem;
						text-align: center;
					}
					.phoneContentDescItem {
						display: flex;
						gap: 0.44rem;
						margin-bottom: 1.44rem;
						.phoneLeft {
							width: 50%;
							// height: 11.88rem;
							border-radius: 16px 16px 16px 16px;
							border: 1.44px solid #e8e8e8;
							cursor: pointer;
							.bill {
								width: 36px;
								height: 36px;
								background-image: url(../../asserts/images/bill.png);
								background-size: contain;
								background-repeat: no-repeat;
								margin-top: 23.04px;
								margin-bottom: 11.52px;
								margin-left: 1rem;
							}
							.telephone {
								width: 36px;
								height: 36px;
								background-image: url(../../asserts/images/telephone.png);
								background-size: contain;
								background-repeat: no-repeat;
								margin-top: 23.04px;
								margin-bottom: 11.52px;
								margin-left: 1rem;
							}
							.creditCard {
								width: 36px;
								height: 36px;
								background-image: url(../../asserts/images/creditCard.png);
								background-size: contain;
								background-repeat: no-repeat;
								margin-top: 23.04px;
								margin-bottom: 11.52px;
								margin-left: 1rem;
							}
							.phoneTitle {
								font-family: DM Sans, DM Sans;
								font-weight: 500;
								font-size: 14.4px;
								margin-left: 1rem;
								padding-right: 1rem;
								margin-bottom: 17.28px;
								max-width: 158.4px;
							}
							.phoneDesc {
								font-family: Inter, Inter;
								font-weight: 500;
								font-size: 11.52px;
								color: #666666;
								margin-bottom: 23.04px;
								width: 80%;
								margin-left: 1rem;
							}
						}
						.phoneRight {
							width: 50%;
							// max-height: 190.08px;
							border-radius: 16px 16px 16px 16px;
							border: 1.44px solid #e8e8e8;
							cursor: pointer;
							.wallet {
								width: 36px;
								height: 36px;
								background-image: url(../../asserts/images/wallet.png);
								background-size: contain;
								background-repeat: no-repeat;
								margin-top: 23.04px;
								margin-bottom: 11.52px;
								margin-left: 1rem;
							}
							.airplane {
								width: 36px;
								height: 36px;
								background-image: url(../../asserts/images/airplane.png);
								background-size: contain;
								background-repeat: no-repeat;
								margin-top: 23.04px;
								margin-bottom: 11.52px;
								margin-left: 1rem;
							}
							.index {
								width: 36px;
								height: 36px;
								background-image: url(../../asserts/images/index.png);
								background-size: contain;
								background-repeat: no-repeat;
								margin-top: 23.04px;
								margin-bottom: 11.52px;
								margin-left: 1rem;
							}
							.phoneTitle {
								font-family: DM Sans, DM Sans;
								font-weight: 500;
								font-size: 14.4px;
								margin-left: 1rem;
								padding-right: 1rem;
								margin-bottom: 17.28px;
								width: 158.4px;
							}
							.phoneDesc {
								font-family: Inter, Inter;
								font-weight: 500;
								font-size: 11.52px;
								margin-bottom: 23.04px;
								color: #666666;
								width: 80%;
								margin-left: 1rem;
							}
						}
					}
				}
				.phoneContentImg {
					width: 50%;
					margin-top: 144px;
					margin-left: 89.28px;
					min-height: 400px;
					margin: 0 auto;
					background-image: url(../../asserts/images/phone.png);
					background-size: contain;
					background-repeat: no-repeat;
				}
			}
		}
		.shop {
			width: 100%;
			margin-top: 115.2px;
		}
		.question {
			width: 100%;
			margin-top: 115.2px;
			margin-bottom: 46.08px;
		}
	}
}
