.footer-wrapper {
    width: 100%;
    max-width: 87.5rem;
    
    opacity: 1;

}
.footer-services {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;

    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
    padding: 8rem 0;
    border-bottom: 1px solid #e8e8e8;
}


.footer-services .footer-company-name {
    color: #5bb5a2 !important;
    font-weight: bold;
    font-size: 22px !important;
}

.footer-services .footer-col {
    color: rgba(26, 25, 30, .6);
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
}

.footer-services .footer-item {
    padding: .5rem 1rem;
    line-height: 120%;
    cursor: pointer;
    letter-spacing: -.5px;
}

.footer-services .footer-title {
    color: #1a191e;
    font-weight: 500;
    margin-bottom: 1rem;
}

.footer-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;
    border-top: 1px solid #f8f8f8;
    color: rgba(26, 25, 30, .6);
    font-size: .875rem;
    text-decoration: none;
}

.footer-left b {
    color: #5bb5a2;
}

.footer-right {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    display: flex;
}