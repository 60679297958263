.about-container {
	text-align: center;
	.about-section {
		padding-bottom: 10.5rem;
		.about-item {
			.about-item-content {
				.about-item-title {
				}
				.about-item-des {
				}
			}
			.about-item-img {
			}
		}
	}
	.serves-section {
		width: 100%;
		min-height: 700px;
		display: flex;
		flex-direction: row;
		gap: 5rem;
		.serves-content {
			width: 27.625rem;
			text-align: start;
			font-family: DM Sans, DM Sans;
			font-weight: 500;
			.serves-title {
				font-size: 40px;
				line-height: 48px;
				margin-bottom: 60px;
			}
			.serves-des {
				font-size: 28px;
				line-height: 34px;
				margin-bottom: 1.125rem;
			}
		}
		.serves-img {
			max-width: 100%;
			width: 42.25rem;
			// height: 22.9375rem;
			img {
				width: 100%;
				// height: 100%;
				object-fit: contain;
			}
		}
	}
	.shop-section {
		padding-top: 12.875rem;
		padding-bottom: 6rem;
	}
	.question-section {
		width: 100%;
		text-align: left;
		padding-bottom: 6rem;
	}
}
@media screen and (max-width: 991px) {
	.about-container {
		.serves-section {
			width: 100%;
			flex-direction: column;
			padding-top: 89px;
			padding-bottom: 89px;
			.serves-content {
				max-width: 100%;
				text-align: center;

				.serves-title {
					font-size: 50px;
				}
				.serves-des {
				}
			}
			.serves-img {
				img {
				}
			}
		}
	}
}
