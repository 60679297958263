.elcetronicWallet {
	.elcetronicWalletContent {
		.elcetronicWalletContentHead {
			width: 100%;
			background-color: #e8f2ee;
			display: flex;
			justify-content: space-between;
			overflow: hidden;
			.headTitle {
				margin-top: 128px;
				margin-left: 140px;
				text-align: left;
				.supprt {
					margin: 0;
					margin-bottom: 16px;
					margin-left: 6px;
					font-family: DM Sans, DM Sans;
					font-weight: 500;
					font-size: 20px;
					color: #1a191e;
					p {
						margin: 0;
					}
				}
				.solve {
					margin: 0;
					font-family: DM Sans, DM Sans;
					font-weight: 500;
					font-size: 80px;
					line-height: 80px;
					color: #1a191e;
					p {
						margin: 0;
					}
				}
				.cash {
					margin: 0;
					font-family: DM Sans, DM Sans;
					font-weight: 500;
					font-size: 80px;
					line-height: 80px;
					color: #1a191e;
					p {
						margin: 0;
					}
				}
			}
			.headImg {
				width: 395px;
				height: 401px;
				margin-top: 72px;
				margin-right: 332px;
				img {
					width: 100%;
					height: 100%;
				}
			}
			.loaded {
				animation: loaded 0.9s ease none;
				transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
					rotateZ(0deg) skew(0deg, 0deg);
				transform-style: preserve-3d;
			}
			.textLoaded {
				animation: loaded 0.5s ease none;
				transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
					rotateZ(0deg) skew(0deg, 0deg);
				transform-style: preserve-3d;
			}
		}
	}
	.elcetronicWalletProvide {
		width: 1200px;
		margin: 80px auto 110px;
		.provideHeader {
			text-align: center;
			width: 728px;
			margin: 0 auto;
			margin-bottom: 80px;
			.provideTitle {
				font-family: Inter, Inter;
				font-weight: 500;
				font-size: 16px;
				color: #37393f;
				line-height: 26px;
				margin-bottom: 8px;
			}
			.provideDesc {
				font-family: DM Sans, DM Sans;
				font-weight: 500;
				font-size: 40px;
				color: #37393f;
				line-height: 48px;
			}
		}
		.provideItem {
			display: flex;
			width: 916px;
			justify-content: space-between;
			margin-bottom: 32px;
			.provideItemBox {
				width: 284px;
				height: 264px;
				border-radius: 16px 16px 16px 16px;
				border: 2px solid #e8e8e8;
				.provideItemBoxUser {
					width: 50px;
					height: 50px;
					margin: 32px 0 16px 32px;
					background-image: url(../../asserts/images/viatechuser.png);
					background-size: contain;
					background-repeat: no-repeat;
				}
				.provideItemBoxMerchant {
					width: 50px;
					height: 50px;
					margin: 32px 0 16px 32px;
					background-image: url(../../asserts/images/viatechmerchant.png);
					background-size: contain;
					background-repeat: no-repeat;
				}
				.provideItemBoxWithdraw {
					width: 50px;
					height: 50px;
					margin: 32px 0 16px 32px;
					background-image: url(../../asserts/images/withdraw.png);
					background-size: contain;
					background-repeat: no-repeat;
				}
				.provideItemBoxTopUp {
					width: 50px;
					height: 50px;
					margin: 32px 0 16px 32px;
					background-image: url(../../asserts/images/topUp.png);
					background-size: contain;
					background-repeat: no-repeat;
				}
				.provideItemBoxTransfer {
					width: 50px;
					height: 50px;
					margin: 32px 0 16px 32px;
					background-image: url(../../asserts/images/transfer.png);
					background-size: contain;
					background-repeat: no-repeat;
				}
				.provideItemBoxService {
					width: 50px;
					height: 50px;
					margin: 32px 0 16px 32px;
					background-image: url(../../asserts/images/service.png);
					background-size: contain;
					background-repeat: no-repeat;
				}
				.provideItemBoxDesc {
					margin: 0 32px 32px 32px;
					font-family: DM Sans, DM Sans;
					font-weight: 500;
					font-size: 20px;
					color: #1a191e;
					line-height: 24px;
					text-align: left;
					font-style: normal;
					text-transform: none;
					.provideItemBoxContent {
						margin-top: 8px;
						font-family: Inter, Inter;
						font-weight: 500;
						font-size: 16px;
						color: #1a191e;
						line-height: 26px;
						text-align: left;
						font-style: normal;
						text-transform: none;
					}
				}
			}
		}
	}
	.elcetronicWalletDetails {
		width: 1400px;
		margin: 0 auto;
		.detailsContent {
			background-image: url(../../asserts/images/vector.png);
			width: 788px;
			height: 3300px;
			margin-bottom: 560px;
			background-size: cover;
			position: relative;
			.detailsWallet {
				position: absolute;
				left: 65px;
				top: -45px;
				display: flex;
				justify-content: space-between;
				.detailsWalletLeft {
					.detailsWalletDesc {
						display: flex;
						gap: 25px;
						.detailsWalletDescIcon {
							width: 58px;
							height: 58px;
							background-image: url(../../asserts/images/walleticon.png);
							background-size: contain;
						}
						.detailsWalletDescText {
							.detailsWalletDescPersonal {
								font-family: Poppins, Poppins;
								font-weight: 500;
								font-size: 17px;
								color: #000000;
								line-height: 30px;
								text-align: left;
								font-style: normal;
								text-transform: none;
							}
							.detailsWalletDescMulti {
								font-family: Poppins, Poppins;
								font-weight: 400;
								font-size: 14px;
								color: #868686;
								line-height: 25px;
								text-align: left;
								font-style: normal;
								text-transform: none;
							}
						}
					}
					.detailsWalletWord {
						margin-top: 45px;
						.detailsWalletWordTitle {
							width: 455px;
							font-family: DM Sans, DM Sans;
							font-weight: 500;
							font-size: 28px;
							color: #1a191e;
							line-height: 34px;
							text-align: left;
							font-style: normal;
							text-transform: none;
						}
						.detailsWalletWordText {
							margin-top: 56px;
							width: 476px;
							font-family: Inter, Inter;
							font-weight: 500;
							font-size: 16px;
							color: #545454;
							line-height: 26px;
							text-align: left;
							font-style: normal;
							text-transform: none;
						}
					}
				}
				.detailsWalletRight {
					width: 457px;
					height: 457px;
					margin-left: 192px;
					margin-top: -135px;
					background-image: url(../../asserts/images/walletphone.png);
					background-size: contain;
					background-repeat: no-repeat;
				}
			}
			.detailsMerchant {
				position: absolute;
				left: 0;
				top: 496px;
				display: flex;
				justify-content: space-between;
				.detailsMerchantLeft {
					width: 536px;
					height: 535px;
					background-image: url(../../asserts/images/merchantphone.png);
					background-size: contain;
					background-repeat: no-repeat;
				}
				.detailsMerchantRight {
					margin-left: 185px;
					margin-top: 75px;
					.detailsMerchantDesc {
						display: flex;
						gap: 25px;
						.detailsMerchantDescIcon {
							width: 58px;
							height: 58px;
							background-image: url(../../asserts/images/merchanticon.png);
							background-size: contain;
						}
						.detailsMerchantDescText {
							.detailsMerchantDescPersonal {
								font-family: Poppins, Poppins;
								font-weight: 500;
								font-size: 17px;
								color: #000000;
								line-height: 30px;
								text-align: left;
								font-style: normal;
								text-transform: none;
							}
							.detailsMerchantDescMulti {
								font-family: Poppins, Poppins;
								font-weight: 400;
								font-size: 14px;
								color: #868686;
								line-height: 25px;
								text-align: left;
								font-style: normal;
								text-transform: none;
							}
						}
					}
					.detailsMerchantWord {
						margin-top: 45px;
						.detailsMerchantWordTitle {
							width: 455px;
							font-family: DM Sans, DM Sans;
							font-weight: 500;
							font-size: 28px;
							color: #1a191e;
							line-height: 34px;
							text-align: left;
							font-style: normal;
							text-transform: none;
						}
						.detailsMerchantWordText {
							margin-top: 56px;
							width: 476px;
							font-family: Inter, Inter;
							font-weight: 500;
							font-size: 16px;
							color: #545454;
							line-height: 26px;
							text-align: left;
							font-style: normal;
							text-transform: none;
						}
					}
				}
			}
			.detailsTopUp {
				position: absolute;
				left: 70px;
				top: 1233px;
				display: flex;
				justify-content: space-between;
				.detailsTopUpLeft {
					.detailsTopUpDesc {
						display: flex;
						gap: 25px;
						.detailsTopUpDescIcon {
							width: 58px;
							height: 58px;
							background-image: url(../../asserts/images/walleticon.png);
							background-size: contain;
						}
						.detailsTopUpDescText {
							.detailsTopUpDescPersonal {
								font-family: Poppins, Poppins;
								font-weight: 500;
								font-size: 17px;
								color: #000000;
								line-height: 30px;
								text-align: left;
								font-style: normal;
								text-transform: none;
							}
							.detailsTopUpDescMulti {
								font-family: Poppins, Poppins;
								font-weight: 400;
								font-size: 14px;
								color: #868686;
								line-height: 25px;
								text-align: left;
								font-style: normal;
								text-transform: none;
							}
						}
					}
					.detailsTopUpWord {
						margin-top: 45px;
						.detailsTopUpWordTitle {
							width: 455px;
							font-family: DM Sans, DM Sans;
							font-weight: 500;
							font-size: 28px;
							color: #1a191e;
							line-height: 34px;
							text-align: left;
							font-style: normal;
							text-transform: none;
						}
						.detailsTopUpWordText {
							margin-top: 56px;
							width: 476px;
							font-family: Inter, Inter;
							font-weight: 500;
							font-size: 16px;
							color: #545454;
							line-height: 26px;
							text-align: left;
							font-style: normal;
							text-transform: none;
						}
					}
				}
				.detailsTopUpRight {
					width: 462px;
					height: 431px;
					margin-left: 199px;
					// margin-top: -135px;
					background-image: url(../../asserts/images/topphone.png);
					background-size: contain;
					background-repeat: no-repeat;
				}
			}
			.detailsWithdraw {
				position: absolute;
				left: 70px;
				top: 1957px;
				display: flex;
				justify-content: space-between;
				.detailsWithdrawLeft {
					width: 439px;
					height: 438px;
					background-image: url(../../asserts/images/withdrawphone.png);
					background-size: contain;
					background-repeat: no-repeat;
				}
				.detailsWithdrawRight {
					margin-left: 220px;
					margin-top: 75px;
					.detailsWithdrawDesc {
						display: flex;
						gap: 25px;
						.detailsWithdrawDescIcon {
							width: 58px;
							height: 58px;
							background-image: url(../../asserts/images/pay.png);
							background-size: contain;
						}
						.detailsWithdrawDescText {
							.detailsWithdrawDescPersonal {
								font-family: Poppins, Poppins;
								font-weight: 500;
								font-size: 17px;
								color: #000000;
								line-height: 30px;
								text-align: left;
								font-style: normal;
								text-transform: none;
							}
							.detailsWithdrawDescMulti {
								font-family: Poppins, Poppins;
								font-weight: 400;
								font-size: 14px;
								color: #868686;
								line-height: 25px;
								text-align: left;
								font-style: normal;
								text-transform: none;
							}
						}
					}
					.detailsWithdrawWord {
						margin-top: 45px;
						.detailsWithdrawWordTitle {
							width: 455px;
							font-family: DM Sans, DM Sans;
							font-weight: 500;
							font-size: 28px;
							color: #1a191e;
							line-height: 34px;
							text-align: left;
							font-style: normal;
							text-transform: none;
						}
						.detailsWithdrawWordText {
							margin-top: 56px;
							width: 476px;
							font-family: Inter, Inter;
							font-weight: 500;
							font-size: 16px;
							color: #545454;
							line-height: 26px;
							text-align: left;
							font-style: normal;
							text-transform: none;
						}
					}
				}
			}
			.detailsTransfer {
				position: absolute;
				left: 68px;
				top: 2637px;
				display: flex;
				justify-content: space-between;
				.detailsTransferLeft {
					.detailsTransferDesc {
						display: flex;
						gap: 25px;
						.detailsTransferDescIcon {
							width: 58px;
							height: 58px;
							background-image: url(../../asserts/images/swap.png);
							background-size: contain;
						}
						.detailsTransferDescText {
							.detailsTransferDescPersonal {
								font-family: Poppins, Poppins;
								font-weight: 500;
								font-size: 17px;
								color: #000000;
								line-height: 30px;
								text-align: left;
								font-style: normal;
								text-transform: none;
							}
							.detailsTransferDescMulti {
								font-family: Poppins, Poppins;
								font-weight: 400;
								font-size: 14px;
								color: #868686;
								line-height: 25px;
								text-align: left;
								font-style: normal;
								text-transform: none;
							}
						}
					}
					.detailsTransferWord {
						margin-top: 45px;
						.detailsTransferWordTitle {
							width: 455px;
							font-family: DM Sans, DM Sans;
							font-weight: 500;
							font-size: 28px;
							color: #1a191e;
							line-height: 34px;
							text-align: left;
							font-style: normal;
							text-transform: none;
						}
						.detailsTransferWordText {
							margin-top: 56px;
							width: 476px;
							font-family: Inter, Inter;
							font-weight: 500;
							font-size: 16px;
							color: #545454;
							line-height: 26px;
							text-align: left;
							font-style: normal;
							text-transform: none;
						}
					}
				}
				.detailsTransferRight {
					width: 462px;
					height: 432px;
					margin-left: 208px;
					background-image: url(../../asserts/images/transferphone.png);
					background-size: contain;
					background-repeat: no-repeat;
				}
			}
			.detailsService {
				position: absolute;
				left: 63px;
				top: 3187px;
				display: flex;
				justify-content: space-between;
				.detailsServiceLeft {
					width: 462px;
					height: 431px;
					margin-top: 75px;
					background-image: url(../../asserts/images/servicephone.png);
					background-size: contain;
					background-repeat: no-repeat;
				}
				.detailsServiceRight {
					margin-left: 225px;
					margin-top: 108px;
					.detailsServiceDesc {
						display: flex;
						gap: 25px;
						.detailsServiceDescIcon {
							width: 58px;
							height: 58px;
							background-image: url(../../asserts/images/serviceicon.png);
							background-size: contain;
						}
						.detailsServiceDescText {
							.detailsServiceDescPersonal {
								font-family: Poppins, Poppins;
								font-weight: 500;
								font-size: 17px;
								color: #000000;
								line-height: 30px;
								text-align: left;
								font-style: normal;
								text-transform: none;
							}
							.detailsServiceDescMulti {
								font-family: Poppins, Poppins;
								font-weight: 400;
								font-size: 14px;
								color: #868686;
								line-height: 25px;
								text-align: left;
								font-style: normal;
								text-transform: none;
							}
						}
					}
					.detailsServiceWord {
						margin-top: 45px;
						.detailsServiceWordTitle {
							width: 455px;
							font-family: DM Sans, DM Sans;
							font-weight: 500;
							font-size: 28px;
							color: #1a191e;
							line-height: 34px;
							text-align: left;
							font-style: normal;
							text-transform: none;
						}
						.detailsServiceWordText {
							margin-top: 56px;
							width: 476px;
							font-family: Inter, Inter;
							font-weight: 500;
							font-size: 16px;
							color: #545454;
							line-height: 26px;
							text-align: left;
							font-style: normal;
							text-transform: none;
						}
					}
				}
			}
		}
	}
	@keyframes loaded {
		from {
			transform: translateY(100%);
		}
		to {
			transform: translateY(0%);
		}
	}
	@keyframes textLoaded {
		from {
			transform: translateY(100%);
		}
		to {
			transform: translateY(0%);
		}
	}
}
@media screen and (max-width: 991px) {
	.elcetronicWallet {
		.elcetronicWalletContent {
			.elcetronicWalletContentHead {
				width: 100%;
				background-color: #e8f2ee;
				display: block;
				justify-content: space-between;
				overflow: hidden;
				.headTitle {
					margin-top: 128px;
					margin-left: 0;
					text-align: center;
					margin: 0 auto 2rem;
					.supprt {
						margin: 0;
						margin-bottom: 16px;
						margin-left: 6px;
						margin-top: 32px;
						font-family: DM Sans, DM Sans;
						font-weight: 500;
						font-size: 20px;
						color: #1a191e;
						p {
							margin: 0;
						}
					}
					.solve {
						margin: 0;
						font-family: DM Sans, DM Sans;
						font-weight: 500;
						font-size: 40px;
						line-height: 80px;
						color: #1a191e;
						p {
							margin: 0;
						}
					}
					.cash {
						margin: 0;
						font-family: DM Sans, DM Sans;
						font-weight: 500;
						font-size: 40px;
						line-height: 80px;
						color: #1a191e;
						p {
							margin: 0;
						}
					}
				}
				.headImg {
					max-width: 395px;
					max-height: 401px;
					margin-top: 72px;
					margin-right: 0;
					margin: 0 auto;
					img {
						width: 100%;
						height: 100%;
					}
				}
				.loaded {
					animation: loaded 0.9s ease none;
					transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
						rotateZ(0deg) skew(0deg, 0deg);
					transform-style: preserve-3d;
				}
				.textLoaded {
					animation: loaded 0.5s ease none;
					transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
						rotateZ(0deg) skew(0deg, 0deg);
					transform-style: preserve-3d;
				}
			}
		}
		.elcetronicWalletProvide {
			width: 100%;
			margin: 80px auto 110px;
			.provideHeader {
				text-align: center;
				width: 100%;
				margin: 0 auto;
				margin-bottom: 80px;
				.provideTitle {
					font-family: Inter, Inter;
					font-weight: 500;
					font-size: 16px;
					color: #37393f;
					line-height: 26px;
					margin-bottom: 8px;
				}
				.provideDesc {
					font-family: DM Sans, DM Sans;
					font-weight: 500;
					font-size: 40px;
					color: #37393f;
					line-height: 48px;
				}
			}
			.provideItem {
				display: block;
				width: 100%;
				justify-content: space-between;
				margin: 0;
				.provideItemBox {
					width: 284px;
					height: 264px;
					border-radius: 16px 16px 16px 16px;
					margin: 0 auto 1rem;
					border: 2px solid #e8e8e8;
					.provideItemBoxUser {
						width: 50px;
						height: 50px;
						margin: 32px 0 16px 32px;
						background-image: url(../../asserts/images/viatechuser.png);
						background-size: contain;
						background-repeat: no-repeat;
					}
					.provideItemBoxMerchant {
						width: 50px;
						height: 50px;
						margin: 32px 0 16px 32px;
						background-image: url(../../asserts/images/viatechmerchant.png);
						background-size: contain;
						background-repeat: no-repeat;
					}
					.provideItemBoxWithdraw {
						width: 50px;
						height: 50px;
						margin: 32px 0 16px 32px;
						background-image: url(../../asserts/images/withdraw.png);
						background-size: contain;
						background-repeat: no-repeat;
					}
					.provideItemBoxTopUp {
						width: 50px;
						height: 50px;
						margin: 32px 0 16px 32px;
						background-image: url(../../asserts/images/topUp.png);
						background-size: contain;
						background-repeat: no-repeat;
					}
					.provideItemBoxTransfer {
						width: 50px;
						height: 50px;
						margin: 32px 0 16px 32px;
						background-image: url(../../asserts/images/transfer.png);
						background-size: contain;
						background-repeat: no-repeat;
					}
					.provideItemBoxService {
						width: 50px;
						height: 50px;
						margin: 32px 0 16px 32px;
						background-image: url(../../asserts/images/service.png);
						background-size: contain;
						background-repeat: no-repeat;
					}
					.provideItemBoxDesc {
						margin: 0 32px 32px 32px;
						font-family: DM Sans, DM Sans;
						font-weight: 500;
						font-size: 20px;
						color: #1a191e;
						line-height: 24px;
						text-align: left;
						font-style: normal;
						text-transform: none;
						.provideItemBoxContent {
							margin-top: 8px;
							font-family: Inter, Inter;
							font-weight: 500;
							font-size: 16px;
							color: #1a191e;
							line-height: 26px;
							text-align: left;
							font-style: normal;
							text-transform: none;
						}
					}
				}
			}
		}
		.elcetronicWalletDetails {
			width: 100%;
			margin: 0 auto;
			.detailsContent {
				background-image: none;
				width: 100%;
				height: 3500px;
				margin-bottom: 560px;
				background-size: cover;
				position: relative;
				.detailsWallet {
					position: relative;
					left: 0;
					top: 0;
					display: block;
					justify-content: space-between;
					margin: 0 auto;
					.detailsWalletLeft {
						.detailsWalletDesc {
							display: flex;
							gap: 25px;
							justify-content: center;
							.detailsWalletDescIcon {
								width: 58px;
								height: 58px;
								background-image: url(../../asserts/images/walleticon.png);
								background-size: contain;
							}
							.detailsWalletDescText {
								.detailsWalletDescPersonal {
									font-family: Poppins, Poppins;
									font-weight: 500;
									font-size: 17px;
									color: #000000;
									line-height: 30px;
									text-align: center;
									margin: 0 auto;
									font-style: normal;
									text-transform: none;
								}
								.detailsWalletDescMulti {
									font-family: Poppins, Poppins;
									font-weight: 400;
									font-size: 14px;
									color: #868686;
									line-height: 25px;
									margin: 0 auto;
									text-align: center;
									font-style: normal;
									text-transform: none;
								}
							}
						}
						.detailsWalletWord {
							margin-top: 45px;
							margin: 1rem auto;
							.detailsWalletWordTitle {
								width: 100%;
								font-family: DM Sans, DM Sans;
								font-weight: 500;
								font-size: 28px;
								color: #1a191e;
								line-height: 34px;
								text-align: center;
								font-style: normal;
								text-transform: none;
								margin: 0 auto;
							}
							.detailsWalletWordText {
								margin-top: 56px;
								width: 100%;
								font-family: Inter, Inter;
								font-weight: 500;
								font-size: 16px;
								color: #545454;
								line-height: 26px;
								text-align: center;
								font-style: normal;
								text-transform: none;
								margin: 2rem auto 0;
							}
						}
					}
					.detailsWalletRight {
						max-width: 300px;
						max-height: 300px;
						margin-left: 0;
						margin-top: 0;
						margin: 0 auto;
						background-image: url(../../asserts/images/walletphone.png);
						background-size: contain;
						background-repeat: no-repeat;
					}
				}
				.detailsMerchant {
					width: 100%;
					position: relative;
					left: 0;
					top: 0;
					display: flex;
					flex-direction: column;
					margin: 0 auto;
					.detailsMerchantLeft {
						width: 300px;
						height: 300px;
						order: 2;
						background-image: url(../../asserts/images/merchantphone.png);
						background-size: contain;
						background-repeat: no-repeat;
						margin: 1rem auto 2rem;
					}
					.detailsMerchantRight {
						margin: 0 auto;
						width: 100%;
						order: 1;
						.detailsMerchantDesc {
							display: flex;
							gap: 25px;
							justify-content: center;
							margin: 1rem auto;
							.detailsMerchantDescIcon {
								width: 58px;
								height: 58px;
								background-image: url(../../asserts/images/merchanticon.png);
								background-size: contain;
							}
							.detailsMerchantDescText {
								.detailsMerchantDescPersonal {
									font-family: Poppins, Poppins;
									font-weight: 500;
									font-size: 17px;
									color: #000000;
									line-height: 30px;
									text-align: center;
									font-style: normal;
									text-transform: none;
								}
								.detailsMerchantDescMulti {
									font-family: Poppins, Poppins;
									font-weight: 400;
									font-size: 14px;
									color: #868686;
									line-height: 25px;
									text-align: center;
									font-style: normal;
									text-transform: none;
								}
							}
						}
						.detailsMerchantWord {
							margin-top: 45px;
							margin: 0 auto;
							.detailsMerchantWordTitle {
								width: 100%;
								font-family: DM Sans, DM Sans;
								font-weight: 500;
								font-size: 28px;
								color: #1a191e;
								line-height: 34px;
								text-align: center;
								font-style: normal;
								text-transform: none;
								margin: 1rem auto;
							}
							.detailsMerchantWordText {
								margin-top: 56px;
								width: 100%;
								font-family: Inter, Inter;
								font-weight: 500;
								font-size: 16px;
								color: #545454;
								line-height: 26px;
								text-align: center;
								font-style: normal;
								text-transform: none;
								margin: 1rem auto;
							}
						}
					}
				}
				.detailsTopUp {
					position: relative;
					left: 0;
					top: 0;
					display: block;
					justify-content: space-between;
					margin: 3rem auto 0;
					.detailsTopUpLeft {
						.detailsTopUpDesc {
							display: flex;
							gap: 25px;
							justify-content: center;
							.detailsTopUpDescIcon {
								width: 58px;
								height: 58px;
								background-image: url(../../asserts/images/walleticon.png);
								background-size: contain;
							}
							.detailsTopUpDescText {
								.detailsTopUpDescPersonal {
									font-family: Poppins, Poppins;
									font-weight: 500;
									font-size: 17px;
									color: #000000;
									line-height: 30px;
									text-align: center;
									font-style: normal;
									text-transform: none;
								}
								.detailsTopUpDescMulti {
									font-family: Poppins, Poppins;
									font-weight: 400;
									font-size: 14px;
									color: #868686;
									line-height: 25px;
									text-align: center;
									font-style: normal;
									text-transform: none;
								}
							}
						}
						.detailsTopUpWord {
							margin-top: 45px;
							margin: 1rem auto;
							.detailsTopUpWordTitle {
								width: 100%;
								font-family: DM Sans, DM Sans;
								font-weight: 500;
								font-size: 28px;
								color: #1a191e;
								line-height: 34px;
								text-align: center;
								font-style: normal;
								text-transform: none;
								margin: 2rem auto;
							}
							.detailsTopUpWordText {
								margin-top: 56px;
								margin: 1rem auto;
								width: 100%;
								font-family: Inter, Inter;
								font-weight: 500;
								font-size: 16px;
								color: #545454;
								line-height: 26px;
								text-align: center;
								font-style: normal;
								text-transform: none;
								margin: 2rem auto;
							}
						}
					}
					.detailsTopUpRight {
						width: 300px;
						height: 300px;
						margin-left: 199px;
						// margin-top: -135px;
						background-image: url(../../asserts/images/topphone.png);
						background-size: contain;
						background-repeat: no-repeat;
						margin: 3rem auto;
					}
				}
				.detailsWithdraw {
					position: relative;
					left: 0;
					top: 0;
					display: flex;
					flex-direction: column;
					.detailsWithdrawLeft {
						width: 300px;
						height: 300px;
						margin: 3rem auto;
						order: 2;
						background-image: url(../../asserts/images/withdrawphone.png);
						background-size: contain;
						background-repeat: no-repeat;
					}
					.detailsWithdrawRight {
						order: 1;
						margin-left: 220px;
						margin-top: 75px;
						margin: 0 auto;
						.detailsWithdrawDesc {
							display: flex;
							gap: 25px;
							margin: 0 auto;
							justify-content: center;
							.detailsWithdrawDescIcon {
								width: 58px;
								height: 58px;
								background-image: url(../../asserts/images/pay.png);
								background-size: contain;
							}
							.detailsWithdrawDescText {
								.detailsWithdrawDescPersonal {
									font-family: Poppins, Poppins;
									font-weight: 500;
									font-size: 17px;
									color: #000000;
									line-height: 30px;
									text-align: center;
									font-style: normal;
									text-transform: none;
								}
								.detailsWithdrawDescMulti {
									font-family: Poppins, Poppins;
									font-weight: 400;
									font-size: 14px;
									color: #868686;
									line-height: 25px;
									text-align: center;
									font-style: normal;
									text-transform: none;
								}
							}
						}
						.detailsWithdrawWord {
							margin-top: 45px;
							margin: 1rem auto;
							.detailsWithdrawWordTitle {
								width: 100%;
								font-family: DM Sans, DM Sans;
								font-weight: 500;
								font-size: 28px;
								color: #1a191e;
								line-height: 34px;
								text-align: center;
								font-style: normal;
								text-transform: none;
								margin: 2rem auto;
							}
							.detailsWithdrawWordText {
								width: 100%;
								font-family: Inter, Inter;
								font-weight: 500;
								font-size: 16px;
								color: #545454;
								line-height: 26px;
								text-align: center;
								font-style: normal;
								text-transform: none;
								margin: 2rem auto;
							}
						}
					}
				}
				.detailsTransfer {
					position: relative;
					left: 0;
					top: 0;
					display: block;
					justify-content: space-between;
					.detailsTransferLeft {
						.detailsTransferDesc {
							display: flex;
							gap: 25px;
							justify-content: center;
							.detailsTransferDescIcon {
								width: 58px;
								height: 58px;
								background-image: url(../../asserts/images/swap.png);
								background-size: contain;
							}
							.detailsTransferDescText {
								.detailsTransferDescPersonal {
									font-family: Poppins, Poppins;
									font-weight: 500;
									font-size: 17px;
									color: #000000;
									line-height: 30px;
									text-align: center;
									font-style: normal;
									text-transform: none;
								}
								.detailsTransferDescMulti {
									font-family: Poppins, Poppins;
									font-weight: 400;
									font-size: 14px;
									color: #868686;
									line-height: 25px;
									text-align: center;
									font-style: normal;
									text-transform: none;
								}
							}
						}
						.detailsTransferWord {
							margin-top: 45px;
							margin: 1rem auto;
							.detailsTransferWordTitle {
								width: 100%;
								font-family: DM Sans, DM Sans;
								font-weight: 500;
								font-size: 28px;
								color: #1a191e;
								line-height: 34px;
								text-align: center;
								font-style: normal;
								text-transform: none;
								margin: 2rem auto;
							}
							.detailsTransferWordText {
								margin-top: 56px;
								width: 100%;
								font-family: Inter, Inter;
								font-weight: 500;
								font-size: 16px;
								color: #545454;
								line-height: 26px;
								text-align: center;
								font-style: normal;
								text-transform: none;
								margin: 2rem auto;
							}
						}
					}
					.detailsTransferRight {
						width: 300px;
						height: 300px;
						margin-left: 208px;
						background-image: url(../../asserts/images/transferphone.png);
						background-size: contain;
						background-repeat: no-repeat;
						margin: 3rem auto;
					}
				}
				.detailsService {
					position: relative;
					left: 0;
					top: 0;
					display: flex;
					flex-direction: column;
					.detailsServiceLeft {
						order: 2;
						width: 300px;
						height: 300px;
						margin-top: 75px;
						background-image: url(../../asserts/images/servicephone.png);
						background-size: contain;
						background-repeat: no-repeat;
						margin: 3rem auto;
					}
					.detailsServiceRight {
						margin-left: 225px;
						margin-top: 108px;
						width: 100%;
						margin: 1rem auto 0;
						.detailsServiceDesc {
							display: flex;
							gap: 25px;
							justify-content: center;
							.detailsServiceDescIcon {
								width: 58px;
								height: 58px;
								background-image: url(../../asserts/images/serviceicon.png);
								background-size: contain;
							}
							.detailsServiceDescText {
								.detailsServiceDescPersonal {
									font-family: Poppins, Poppins;
									font-weight: 500;
									font-size: 17px;
									color: #000000;
									line-height: 30px;
									text-align: center;
									font-style: normal;
									text-transform: none;
								}
								.detailsServiceDescMulti {
									font-family: Poppins, Poppins;
									font-weight: 400;
									font-size: 14px;
									color: #868686;
									line-height: 25px;
									text-align: center;
									font-style: normal;
									text-transform: none;
								}
							}
						}
						.detailsServiceWord {
							margin-top: 45px;
							margin: 1rem auto;
							.detailsServiceWordTitle {
								width: 100%;
								font-family: DM Sans, DM Sans;
								font-weight: 500;
								font-size: 28px;
								color: #1a191e;
								line-height: 34px;
								text-align: center;
								font-style: normal;
								text-transform: none;
								margin: 2rem auto;
							}
							.detailsServiceWordText {
								margin-top: 56px;
								width: 100%;
								font-family: Inter, Inter;
								font-weight: 500;
								font-size: 16px;
								color: #545454;
								line-height: 26px;
								text-align: center;
								font-style: normal;
								text-transform: none;
								margin: 2rem auto;
							}
						}
					}
				}
			}
		}
		@keyframes loaded {
			from {
				transform: translateY(100%);
			}
			to {
				transform: translateY(0%);
			}
		}
		@keyframes textLoaded {
			from {
				transform: translateY(100%);
			}
			to {
				transform: translateY(0%);
			}
		}
	}
}
