.creditCard-container {
	width: 100%;
	.banner-section {
		.banner-container {
			.banner-img {
				width: 441px;
			}
		}
	}
	.limit-section {
		.limit-container {
			.limit-iterm {
				&:nth-child(2) {
					// .limit-img {
					// 	background-image: url(../../asserts/images/limit-iterm-02.png);
					// }
				}
				&:nth-child(3) {
					// .limit-img {
					// 	background-image: url(../../asserts/images/limit-iterm-03.png);
					// }
				}
			}
		}
	}
	.contact-section {
		padding-top: 8rem;
		padding-bottom: 7.75rem;
		.conract-title {
			width: 100%;
			max-width: 29.875rem;
			font-family: DM Sans, DM Sans;
			font-weight: 500;
			font-size: 2rem;
			line-height: 3.25rem;
			text-align: center;
			margin-bottom: 2.0625rem;
		}
	}
}
