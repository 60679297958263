.latin {
	.latinHeader {
		width: 100%;
		background-color: #e8f2ee;
	}
	.latinPay {
		width: 100%;
		margin-top: 2rem;
		img {
			width: 100%;
			object-fit: contain;
		}
	}
	.about-item-img {
		.crossBorderToolsTag {
			margin-top: 138px;
			.crossBorderToolsTagBox {
				display: flex;
				gap: 16px;
				margin-bottom: 20px;
				.crossBorderToolsTagBoxIcon {
					width: 40px;
					height: 40px;
					background-image: url(../../asserts/images/hook.png);
					background-size: contain;
					background-repeat: no-repeat;
				}
				.crossBorderToolsTagBoxText {
					font-family: Inter, Inter;
					font-weight: 500;
					font-size: 18px;
					color: #666666;
					line-height: 32px;
					text-align: left;
					font-style: normal;
					text-transform: none;
				}
			}
		}
	}
	.crossBorderTools {
		width: 1200px;
		margin: 0 auto;
		margin-top: 160px;
		display: flex;
		justify-content: space-between;
		.crossBorderToolsDetails {
			.crossBorderToolsDetailsTitle {
				font-family: DM Sans, DM Sans;
				font-weight: 500;
				font-size: 20px;
				color: #1a191e;
				line-height: 24px;
				text-align: left;
				font-style: normal;
				text-transform: none;
				margin-bottom: 8px;
			}
			.crossBorderToolsDetailsDesc {
				font-family: DM Sans, DM Sans;
				font-weight: 500;
				font-size: 28px;
				color: #1a191e;
				line-height: 34px;
				text-align: left;
				font-style: normal;
				text-transform: none;
			}
			.crossBorderToolsDetailsImg {
				width: 588px;
				height: 318px;
				background-image: url(../../asserts/images/paybank.png);
				background-size: contain;
				background-repeat: no-repeat;
				background-position: left;
			}
		}
		.crossBorderToolsTag {
			margin-top: 138px;
			.crossBorderToolsTagBox {
				display: flex;
				gap: 16px;
				margin-bottom: 20px;
				.crossBorderToolsTagBoxIcon {
					width: 40px;
					height: 40px;
					background-image: url(../../asserts/images/hook.png);
					background-size: contain;
					background-repeat: no-repeat;
				}
				.crossBorderToolsTagBoxText {
					font-family: Inter, Inter;
					font-weight: 500;
					font-size: 18px;
					color: #666666;
					line-height: 32px;
					text-align: left;
					font-style: normal;
					text-transform: none;
				}
			}
		}
	}
	.question {
		width: 100%;
		margin: 3rem auto;
	}
}
