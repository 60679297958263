.questions {
	width: 1200px;
	background-color: #5bb5a2;
	border-radius: 20px 20px 20px 20px;
	display: flex;
	justify-content: space-between;
	.text {
		width: 500px;
		margin-left: 32px;
		margin-top: 32px;
		color: #ffffff;
		.textCenter {
			align-items: center;
			.textQuestion {
				margin: 0;
				margin-bottom: 8px;
				font-family: DM Sans, sans-serif;
				font-size: 28px;
				font-weight: 500;
			}
			.textHelp {
				margin: 0;
				color: rgba(255, 255, 255, 0.7);
				font-size: 18px;
				margin-bottom: 32px;
			}
		}
	}
	.contactBox {
		width: 500px;
		margin-right: 32px;
		margin-top: 32px;
		display: flex;
		justify-content: space-between;
		.email {
			display: flex;
			.emailIcon {
				width: 50px;
				height: 50px;
				margin-right: 16px;
				// background-color: #80bbac;
				border-radius: 50%;
				background-image: url(../../asserts/images/email.png);
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
			}
			.emailText {
				color: #ffffff;
				p {
					margin: 0;
				}
			}
		}
		.navbar-right {
			width: 149px;
			height: 50px;
			text-align: center;
			grid-column-gap: 1rem;
			grid-row-gap: 1rem;
			justify-content: flex-start;
			align-items: center;
			display: flex;
			a {
				width: 149px;
				background-color: black;
				color: #fff;
				font-weight: 500;
				padding: 0.5rem 1rem;
				font-size: 1rem;
				border-radius: 6px;
				&:hover {
					background-color: black;
				}
			}
		}
		.contactBtn {
			text-align: center;
			line-height: 50px;
			border-radius: 8px;
		}
	}
}
@media screen and (max-width: 991px) {
	.questions {
		width: 100%;
		background-color: #5bb5a2;
		border-radius: 20px 20px 20px 20px;
		display: block;
		// justify-content: space-between;
		.text {
			width: 500 * 0.72px;
			margin-left: 32 * 0.72px;
			margin-top: 2rem;
			margin: 2rem auto;
			text-align: center;
			color: #ffffff;
			.textQuestion {
				margin: 0;
				margin-bottom: 8 * 0.72px;
				font-family: DM Sans, sans-serif;
				font-size: 28 * 0.72px;
				font-weight: 500;
			}
			.textHelp {
				margin: 0;
				color: rgba(255, 255, 255, 0.7);
				font-size: 18px;
				margin-bottom: 32px;
			}
		}
		.contactBox {
			width: 500 * 0.72px;
			margin-right: 32 * 0.72px;
			margin-top: 2rem;
			display: block;
			// justify-content: space-between;
			margin: 0 auto;
			.email {
				display: flex;
				justify-content: center;
				.emailIcon {
					width: 50 * 0.72px;
					height: 50 * 0.72px;
					margin-right: 16 * 0.72px;
					// background-color: #80bbac;
					border-radius: 50%;
					background-image: url(../../asserts/images/email.png);
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
				}
				.emailText {
					color: #ffffff;
					p {
						margin: 0;
					}
				}
			}
			.navbar-right {
				width: 149 * 0.72px;
				height: 50 * 0.72px;
				margin: 1.5rem auto;
				text-align: center;
				grid-column-gap: 1 * 0.72rem;
				grid-row-gap: 1 * 0.72rem;
				justify-content: flex-start;
				align-items: center;
				display: flex;
				a {
					width: 149 * 0.72px;
					background-color: black;
					color: #fff;
					font-weight: 500;
					padding: 0.5 * 0.72rem 1 * 0.72rem;
					font-size: 1 * 0.72rem;
					border-radius: 6px;
					&:hover {
						background-color: black;
					}
				}
			}
			.contactBtn {
				text-align: center;
				line-height: 50 * 0.72px;
				border-radius: 8 * 0.72px;
			}
		}
	}
}
