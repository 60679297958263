.payment-container {
	.banner-section {
		max-height: 469px;
		overflow: hidden;
		.banner-container {
			.banner-content {
				.banner-des {
					font-size: 1.25rem;
					color: #666666;
					line-height: 1.5rem;
					margin-bottom: 1rem;
				}
				.banner-title {
					margin-bottom: 0;
				}
			}
		}
	}
	.about-section {
		.about-item {
			.about-item-content {
				.about-item-title {
				}
				.about-item-des {
				}
			}
			.about-item-img {
			}
		}
	}
	.limit-section {
		.limit-container {
			.limit-iterm {
			}
		}
	}
	.question-section {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}
}

@media screen and (max-width: 991px) {
	.payment-container {
		.banner-section {
			max-height: 572px;
			.banner-container {
				.banner-content {
					.banner-des {
					}
					.banner-title {
					}
				}
			}
		}
	}
}
