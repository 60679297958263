.faq {
	.faqHeader {
		width: 100%;
		height: 546px;
		background-color: #e8f2ee;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
		.headTitle {
			margin-top: 128px;
			margin-left: 140px;
			text-align: left;
			.supprt {
				margin: 0;
				margin-bottom: 16px;
				margin-left: 6px;
				font-family: DM Sans, DM Sans;
				font-weight: 500;
				font-size: 20px;
				color: #1a191e;
				p {
					margin: 0;
				}
			}
			.solve {
				margin: 0;
				font-family: DM Sans, DM Sans;
				font-weight: 500;
				font-size: 80px;
				line-height: 80px;
				color: #1a191e;
				p {
					margin: 0;
				}
			}
			.cash {
				margin: 0;
				font-family: DM Sans, DM Sans;
				font-weight: 500;
				font-size: 80px;
				line-height: 80px;
				color: #1a191e;
				p {
					margin: 0;
				}
			}
		}
		.headImg {
			width: 470px;
			height: 990px;
			margin-top: 72px;
			margin-right: 260px;
			img {
				width: 470px;
				height: 990px;
			}
		}
		.loaded {
			animation: loaded 0.9s ease none;
			transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
				rotateZ(0deg) skew(0deg, 0deg);
			transform-style: preserve-3d;
		}
		.textLoaded {
			animation: textLoaded 0.5s ease none;
			transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
				rotateZ(0deg) skew(0deg, 0deg);
			transform-style: preserve-3d;
		}
	}
	.faqContent {
		width: 1200px;
		margin: 100px auto 60px;
		.setupCard {
			width: 100%;
			border-bottom: 1px solid #e8e8e8;
			overflow: hidden;
			margin-bottom: 18px;
			.setupCardTitle {
				display: flex;
				justify-content: space-between;
				width: 100%;
				min-height: 65px;
				cursor: pointer;
				.setupCardTitleText {
					font-size: 24px;
					font-weight: 500;
					font-family: DM Sans, DM Sans;
					line-height: 24px;
				}
				.setupCardTitleIcon {
					width: 36px;
					height: 36px;
					transform: rotate(0);
					transition: transform 0.3s ease;
					img {
						width: 100%;
						height: 100%;
					}
				}
				.setupCardTitleIconActive {
					width: 36px;
					height: 36px;
					transform: rotate(45deg);
					transition: transform 0.3s ease;
					transform-style: preserve-3d;
					img {
						width: 100%;
						height: 100%;
					}
				}
			}
			.setupCardDescActive {
				font-family: Poppins, Poppins;
				font-weight: 400;
				font-size: 16px;
				height: 50px;
				margin-bottom: 16px;
				color: #868686;
				transition: height 0.5s ease;
				transform-style: preserve-3d;
			}
			.setupCardDesc {
				height: 0;
				transition: height 0.3s ease;
				transform-style: preserve-3d;
			}
		}
		.virtualCard {
			width: 100%;
			border-bottom: 1px solid #e8e8e8;
			overflow: hidden;
			margin-bottom: 18px;
			.virtualCardTitle {
				display: flex;
				justify-content: space-between;
				width: 100%;
				min-height: 65px;
				cursor: pointer;
				.virtualCardTitleText {
					font-size: 24px;
					font-weight: 500;
					font-family: DM Sans, DM Sans;
					line-height: 24px;
				}
				.virtualCardTitleIcon {
					width: 36px;
					height: 36px;
					transform: rotate(0);
					transition: transform 0.3s ease;
					img {
						width: 100%;
						height: 100%;
					}
				}
				.virtualCardTitleIconActive {
					width: 36px;
					height: 36px;
					transform: rotate(45deg);
					transition: transform 0.3s ease;
					transform-style: preserve-3d;
					img {
						width: 100%;
						height: 100%;
					}
				}
			}
			.setupCardDescActive {
				font-family: Poppins, Poppins;
				font-weight: 400;
				font-size: 16px;
				height: 70px;
				margin-bottom: 16px;
				color: #868686;
				transition: height 0.5s ease;
				transform-style: preserve-3d;
			}
			.setupCardDesc {
				height: 0;
				transition: height 0.3s ease;
				transform-style: preserve-3d;
			}
		}
		.extraCard {
			width: 100%;
			border-bottom: 1px solid #e8e8e8;
			overflow: hidden;
			margin-bottom: 18px;
			.extraCardTitle {
				display: flex;
				justify-content: space-between;
				width: 100%;
				min-height: 65px;
				cursor: pointer;
				.extraCardTitleText {
					font-size: 24px;
					font-weight: 500;
					font-family: DM Sans, DM Sans;
					line-height: 24px;
				}
				.extraCardTitleIcon {
					width: 36px;
					height: 36px;
					transform: rotate(0);
					transition: transform 0.3s ease;
					img {
						width: 100%;
						height: 100%;
					}
				}
				.extraCardTitleIconActive {
					width: 36px;
					height: 36px;
					transform: rotate(45deg);
					transition: transform 0.3s ease;
					transform-style: preserve-3d;
					img {
						width: 100%;
						height: 100%;
					}
				}
			}
			.setupCardDescActive {
				font-family: Poppins, Poppins;
				font-weight: 400;
				font-size: 16px;
				height: 50px;
				margin-bottom: 16px;
				color: #868686;
				transition: height 0.5s ease;
				transform-style: preserve-3d;
			}
			.setupCardDesc {
				height: 0;
				transition: height 0.3s ease;
				transform-style: preserve-3d;
			}
		}
		.purchaseCard {
			width: 100%;
			border-bottom: 1px solid #e8e8e8;
			overflow: hidden;
			margin-bottom: 18px;
			.purchaseCardTitle {
				display: flex;
				justify-content: space-between;
				width: 100%;
				min-height: 65px;
				cursor: pointer;
				.purchaseCardTitleText {
					font-size: 24px;
					font-weight: 500;
					font-family: DM Sans, DM Sans;
					line-height: 24px;
				}
				.purchaseCardTitleIcon {
					width: 36px;
					height: 36px;
					transform: rotate(0);
					transition: transform 0.3s ease;
					img {
						width: 100%;
						height: 100%;
					}
				}
				.purchaseCardTitleIconActive {
					width: 36px;
					height: 36px;
					transform: rotate(45deg);
					transition: transform 0.3s ease;
					transform-style: preserve-3d;
					img {
						width: 100%;
						height: 100%;
					}
				}
			}
			.setupCardDescActive {
				font-family: Poppins, Poppins;
				font-weight: 400;
				font-size: 16px;
				height: 260px;
				text-align: left;
				margin-bottom: 16px;
				color: #868686;
				transition: height 0.5s ease;
				transform-style: preserve-3d;
				p {
					margin: 0;
					margin-bottom: 5px;
				}
			}
			.setupCardDesc {
				height: 0;
				width: 100%;
				transition: height 0.3s ease;
				transform-style: preserve-3d;
			}
		}
		.cancelCard {
			width: 100%;
			overflow: hidden;
			margin-bottom: 18px;
			.cancelCardTitle {
				display: flex;
				justify-content: space-between;
				width: 100%;
				min-height: 55px;
				cursor: pointer;
				.cancelCardTitleText {
					font-size: 24px;
					font-weight: 500;
					font-family: DM Sans, DM Sans;
					line-height: 24px;
				}
				.cancelCardTitleIcon {
					width: 36px;
					height: 36px;
					transform: rotate(0);
					transition: transform 0.3s ease;
					img {
						width: 100%;
						height: 100%;
					}
				}
				.cancelCardTitleIconActive {
					width: 36px;
					height: 36px;
					transform: rotate(45deg);
					transition: transform 0.3s ease;
					transform-style: preserve-3d;
					img {
						width: 100%;
						height: 100%;
					}
				}
			}
			.setupCardDescActive {
				font-family: Poppins, Poppins;
				font-weight: 400;
				font-size: 16px;
				height: 100px;
				margin-bottom: 16px;
				color: #868686;
				p {
					margin: 0;
					margin-bottom: 5px;
				}
				transition: height 0.5s ease;
				transform-style: preserve-3d;
			}
			.setupCardDesc {
				height: 0;
				transition: height 0.3s ease;
				transform-style: preserve-3d;
			}
		}
	}
	.question {
		width: 100%;
		margin-bottom: 128px;
		margin-top: 64px;
	}
	@keyframes loaded {
		from {
			transform: translateY(100%);
		}
		to {
			transform: translateY(0%);
		}
	}
	@keyframes textLoaded {
		from {
			transform: translateY(100%);
		}
		to {
			transform: translateY(0%);
		}
	}
}
@media screen and (max-width: 991px) {
	.faq {
		.faqHeader {
			width: 100%;
			// height: 546px;
			background-color: #e8f2ee;
			display: block;
			justify-content: space-between;
			overflow: hidden;
			.headTitle {
				// margin-top: 128px;
				// margin-left: 140px;
				margin: 0 auto;
				text-align: center;
				.supprt {
					margin: 0;
					margin-bottom: 16px;
					margin-left: 6px;
					margin: 1rem auto;
					font-family: DM Sans, DM Sans;
					font-weight: 500;
					font-size: 20px;
					color: #1a191e;
					p {
						margin: 0;
					}
				}
				.solve {
					margin: 0;
					font-family: DM Sans, DM Sans;
					font-weight: 500;
					font-size: 55px;
					line-height: 55px;
					margin: 1rem auto;
					color: #1a191e;
					p {
						margin: 0;
					}
				}
				.cash {
					margin: 0;
					font-family: DM Sans, DM Sans;
					font-weight: 500;
					font-size: 55px;
					line-height: 55px;
					color: #1a191e;
					p {
						margin: 0;
					}
				}
			}
			.headImg {
				width: 235px;
				height: 495px;
				margin-top: 72px;
				margin-right: 260px;
				margin: 4rem auto;
				img {
					width: 100%;
					height: 100%;
				}
			}
			.loaded {
				animation: loaded 0.9s ease none;
				transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
					rotateZ(0deg) skew(0deg, 0deg);
				transform-style: preserve-3d;
			}
			.textLoaded {
				animation: textLoaded 0.5s ease none;
				transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
					rotateZ(0deg) skew(0deg, 0deg);
				transform-style: preserve-3d;
			}
		}
		.faqContent {
			width: 100%;
			margin: 2rem auto 1rem;
			.setupCard {
				width: 100%;
				border-bottom: 1px solid #e8e8e8;
				overflow: hidden;
				margin-bottom: 18px;
				.setupCardTitle {
					display: flex;
					justify-content: space-between;
					width: 100%;
					min-height: 65px;
					cursor: pointer;
					.setupCardTitleText {
						font-size: 18px;
						font-weight: 500;
						width: 70%;
						font-family: DM Sans, DM Sans;
						line-height: 18px;
					}
					.setupCardTitleIcon {
						width: 36px;
						height: 36px;
						transform: rotate(0);
						transition: transform 0.3s ease;
						img {
							width: 100%;
							height: 100%;
						}
					}
					.setupCardTitleIconActive {
						width: 36px;
						height: 36px;
						transform: rotate(45deg);
						transition: transform 0.3s ease;
						transform-style: preserve-3d;
						img {
							width: 100%;
							height: 100%;
						}
					}
				}
				.setupCardDescActive {
					width: 100%;
					font-family: Poppins, Poppins;
					font-weight: 400;
					font-size: 16px;
					min-height: 50px;
					text-align: left;
					margin-bottom: 16px;
					color: #868686;
					transition: height 0.5s ease;
					transform-style: preserve-3d;
				}
				.setupCardDesc {
					width: 100%;
					height: 0;
					transition: height 0.3s ease;
					transform-style: preserve-3d;
				}
			}
			.virtualCard {
				width: 100%;
				border-bottom: 1px solid #e8e8e8;
				overflow: hidden;
				margin-bottom: 18px;
				.virtualCardTitle {
					display: flex;
					justify-content: space-between;
					width: 100%;
					min-height: 65px;
					cursor: pointer;
					.virtualCardTitleText {
						font-size: 18px;
						font-weight: 500;
						font-family: DM Sans, DM Sans;
						line-height: 18px;
						width: 70%;
					}
					.virtualCardTitleIcon {
						width: 36px;
						height: 36px;
						transform: rotate(0);
						transition: transform 0.3s ease;
						img {
							width: 100%;
							height: 100%;
						}
					}
					.virtualCardTitleIconActive {
						width: 36px;
						height: 36px;
						transform: rotate(45deg);
						transition: transform 0.3s ease;
						transform-style: preserve-3d;
						img {
							width: 100%;
							height: 100%;
						}
					}
				}
				.setupCardDescActive {
					width: 100%;
					font-family: Poppins, Poppins;
					font-weight: 400;
					font-size: 16px;
					min-height: 200px;
					text-align: left;

					margin-bottom: 16px;
					color: #868686;
					transition: height 0.5s ease;
					transform-style: preserve-3d;
				}
				.setupCardDesc {
					height: 0;
					transition: height 0.3s ease;
					transform-style: preserve-3d;
				}
			}
			.extraCard {
				width: 100%;
				border-bottom: 1px solid #e8e8e8;
				overflow: hidden;
				margin-bottom: 18px;
				.extraCardTitle {
					display: flex;
					justify-content: space-between;
					width: 100%;
					min-height: 65px;
					cursor: pointer;
					.extraCardTitleText {
						font-size: 18px;
						font-weight: 500;
						font-family: DM Sans, DM Sans;
						line-height: 18px;
						width: 70%;
					}
					.extraCardTitleIcon {
						width: 36px;
						height: 36px;
						transform: rotate(0);
						transition: transform 0.3s ease;
						img {
							width: 100%;
							height: 100%;
						}
					}
					.extraCardTitleIconActive {
						width: 36px;
						height: 36px;
						transform: rotate(45deg);
						transition: transform 0.3s ease;
						transform-style: preserve-3d;
						img {
							width: 100%;
							height: 100%;
						}
					}
				}
				.setupCardDescActive {
					width: 100%;
					font-family: Poppins, Poppins;
					font-weight: 400;
					font-size: 16px;
					min-height: 150px;
					margin-bottom: 16px;
					text-align: left;

					color: #868686;
					transition: height 0.5s ease;
					transform-style: preserve-3d;
				}
				.setupCardDesc {
					height: 0;
					transition: height 0.3s ease;
					transform-style: preserve-3d;
				}
			}
			.purchaseCard {
				width: 100%;
				border-bottom: 1px solid #e8e8e8;
				overflow: hidden;
				margin-bottom: 18px;
				.purchaseCardTitle {
					display: flex;
					justify-content: space-between;
					width: 100%;
					min-height: 65px;
					cursor: pointer;
					.purchaseCardTitleText {
						font-size: 18px;
						font-weight: 500;
						font-family: DM Sans, DM Sans;
						line-height: 18px;
						width: 70%;
					}
					.purchaseCardTitleIcon {
						width: 36px;
						height: 36px;
						transform: rotate(0);
						transition: transform 0.3s ease;
						img {
							width: 100%;
							height: 100%;
						}
					}
					.purchaseCardTitleIconActive {
						width: 36px;
						height: 36px;
						transform: rotate(45deg);
						transition: transform 0.3s ease;
						transform-style: preserve-3d;
						img {
							width: 100%;
							height: 100%;
						}
					}
				}
				.setupCardDescActive {
					width: 100%;
					font-family: Poppins, Poppins;
					font-weight: 400;
					font-size: 16px;
					height: 660px;
					text-align: left;
					margin-bottom: 16px;
					color: #868686;
					transition: height 0.5s ease;
					transform-style: preserve-3d;
					p {
						margin: 0;
						margin-bottom: 5px;
					}
				}
				.setupCardDesc {
					height: 0;
					width: 100%;
					transition: height 0.3s ease;
					transform-style: preserve-3d;
				}
			}
			.cancelCard {
				width: 100%;
				overflow: hidden;
				margin-bottom: 18px;
				.cancelCardTitle {
					display: flex;
					justify-content: space-between;
					width: 100%;
					min-height: 55px;
					cursor: pointer;
					.cancelCardTitleText {
						font-size: 18px;
						font-weight: 500;
						font-family: DM Sans, DM Sans;
						line-height: 18px;
						width: 70%;
					}
					.cancelCardTitleIcon {
						width: 36px;
						height: 36px;
						transform: rotate(0);
						transition: transform 0.3s ease;
						img {
							width: 100%;
							height: 100%;
						}
					}
					.cancelCardTitleIconActive {
						width: 36px;
						height: 36px;
						transform: rotate(45deg);
						transition: transform 0.3s ease;
						transform-style: preserve-3d;
						img {
							width: 100%;
							height: 100%;
						}
					}
				}
				.setupCardDescActive {
					width: 100%;
					font-family: Poppins, Poppins;
					font-weight: 400;
					font-size: 16px;
					height: 220px;
					text-align: left;
					margin-bottom: 16px;
					color: #868686;
					p {
						margin: 0;
						margin-bottom: 5px;
					}
					transition: height 0.5s ease;
					transform-style: preserve-3d;
				}
				.setupCardDesc {
					height: 0;
					transition: height 0.3s ease;
					transform-style: preserve-3d;
				}
			}
		}
		.question {
			width: 100%;
			margin-bottom: 128px;
			margin-top: 64px;
		}
		@keyframes loaded {
			from {
				transform: translateY(100%);
			}
			to {
				transform: translateY(0%);
			}
		}
		@keyframes textLoaded {
			from {
				transform: translateY(100%);
			}
			to {
				transform: translateY(0%);
			}
		}
	}
}
