.about-banner {
	overflow: hidden;
	text-align: center;
	.banner-content {
		width: 100%;
		max-width: 800px;
		margin: auto;
		padding: 8rem 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		.banner-title {
			letter-spacing: -2px;
			margin: 1rem;
			font-family: DM Sans, sans-serif;
			font-size: 5rem;
			font-weight: 500;
			line-height: 110%;
		}
		.banner-des {
			font-family: Inter, Inter;
			color: #666666;
			font-weight: 500;
			font-size: 20px;
			line-height: 36px;
			text-align: center;
			font-style: normal;
			text-transform: none;
			padding: 0 6.25rem;
			margin-bottom: 2rem;
		}
	}
	.features-hero-cards {
		justify-content: center;
		width: 100%;
		height: 300px;
		display: flex;
		overflow: hidden;
		.features-hero-cards-inner {
			grid-column-gap: 4rem;
			grid-row-gap: 4rem;
			flex: none;
			justify-content: center;
			align-items: flex-start;
			width: 120%;
			display: flex;
			position: relative;

			img {
				width: 100%;
				max-width: 264px;
				display: inline-block;
			}
			.goldImg {
				transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
					rotateZ(0deg) skew(0deg, 0deg);
				transform-style: preserve-3d;
				img {
					animation: goldImgIn 0.9s ease none;
					transform: translate3d(0px, 30%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
						rotateZ(0deg) skew(0deg, 0deg);
					transform-style: preserve-3d;
				}
			}
			.premiumImg {
				transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
					rotateZ(0deg) skew(0deg, 0deg);
				transform-style: preserve-3d;
				img {
					animation: premiumImgIn 0.9s ease none;
					transform: translate3d(0px, 15%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
						rotateZ(0deg) skew(0deg, 0deg);
					transform-style: preserve-3d;
				}
			}
			.freeImg {
				animation: freeImgIn 0.3s ease none;
				transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
					rotateZ(0deg) skew(0deg, 0deg);
				transform-style: preserve-3d;
			}

			.features-hero-card-wrapper {
				img {
					&:hover {
						transition: transform 0.6s ease;
						transform: translateY(0%);
					}
				}
			}
		}
	}
}
@keyframes freeImgIn {
	from {
		transform: translateY(100%);
	}
	to {
		transform: translateY(0%);
	}
}
@keyframes goldImgIn {
	from {
		transform: translateY(100%);
	}
	to {
		transform: translateY(30%);
	}
}
@keyframes premiumImgIn {
	from {
		transform: translateY(100%);
	}
	to {
		transform: translateY(15%);
	}
}

@media screen and (max-width: 991px) {
	.about-banner {
		.banner-content{
			padding: 4rem 1.5rem;
			.banner-title{
				font-size: 44px;
			}
			
			.banner-des{
				padding: 0;
			}
		}
		.features-hero-cards {
			height: 140px;
			.features-hero-cards-inner {
				grid-column-gap: 1rem;
				grid-row-gap: 1rem;
				width: 110%;
				.features-hero-card-wrapper.goldImg
				{
					display: none;
				}
				img {
					max-width: 100%;
					display: inline-block;
				}
			}
		}
	}
}
