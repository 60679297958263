.dashbord {
	.about-section {
		.dashbord-title {
			font-family: DM Sans, DM Sans;
			font-weight: 500;
			font-size: 28px;
			color: #1a191e;
			line-height: 34px;
			text-align: left;
			margin: 0 auto 3rem auto;
			width: 100%;
			max-width: 1200px;
		}
		.about-item {
			.about-item-content {
				.about-item-des {
					.dashbord-box {
						width: 100%;
						background: #f6f6f6;
						border-radius: 20px 20px 20px 20px;
						padding: 2rem;
						img {
							width: 100%;
						}
						.dashbord-box-title {
							font-family: DM Sans, DM Sans;
							font-weight: 500;
							font-size: 24px;
							color: #1a191e;
							line-height: 29px;
							text-align: left;
							margin: 1rem 0;
						}
						.dashbord-box-desc {
							font-family: Inter, Inter;
							font-weight: 500;
							font-size: 18px;
							color: #666666;
							line-height: 32px;
							text-align: left;
						}
					}
				}
			}
		}
	}
	.question {
		width: 100%;
		margin: 3rem auto;
	}
}
