.businessSupport {
	.bankCordContentImg {
		.imgContent {
			// margin-bottom: -12rem;
			width: 300px;
			height: 500px;
			position: relative;
			.imgContentCord_1 {
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 15;
				img {
					width: 300px;
					height: 500px;
				}
			}
			.imgContentCord_2 {
				// position: absolute;
				// width: 100%;
				// height: 100%;
				// left: 50px;
				// bottom: 66px;
				// img {
				// 	width: 300px;
				// 	height: 500px;
				// }
				z-index: 10;
				transform-origin: 100% 100%;
				position: absolute;
				top: 6%;
				bottom: auto;
				left: 0%;
				right: 0%;
				transform-style: preserve-3d;
				transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
					rotateZ(14deg) skew(0deg, 0deg);
				img {
					width: 300px;
					height: 500px;
				}
			}
		}
	}
	.question {
		width: 100%;
		margin: 3rem auto;
	}
}
