.shopCard {
	width: 1200px;
	background-color: #5bb5a2;
	border-radius: 20px 20px 20px 20px;
	display: flex;
	justify-content: space-evenly;
	.shopCardDesc {
		width: 600px;
		color: #ffffff;
		.bankCordContentTextTitle {
			width: 80%;
			margin-top: 96px;
			margin-left: 76px;
			font-family: DM Sans, DM Sans;
			font-weight: 500;
			text-align: left;
			p {
				font-size: 64px;
				margin-bottom: 3rem;
			}
		}
		.bankCordContentTextDesc {
			font-size: 1.3rem;
			font-weight: 500;
			margin-left: 76px;
			width: 524px;
			text-align: left;
			font-family: Inter, Inter;
		}
		.bankCordContentTextTag {
			width: 100%;
			margin-left: 76px;
			margin-top: 32px;
			font-size: 0.9rem;
			.bankCordContentTextTagItem {
				width: 100%;
				display: flex;
				margin-bottom: 0.5rem;
				// justify-content: space-between;
				align-items: center;
				.tagLeft {
					width: 40%;
					display: flex;
					align-items: center;
					gap: 8px;
					.tagIcon {
						width: 1.5rem;
						height: 1.5rem;
						background-image: url('../../asserts/images/hook.png');
						background-size: 100% 100%;
					}
				}
				.tagRight {
					width: 40%;
					display: flex;
					gap: 8px;
					align-items: center;
					.tagIcon {
						width: 1.5rem;
						height: 1.5rem;
						background-image: url('../../asserts/images/hook.png');
						background-size: 100% 100%;
					}
				}
			}
		}
		.shopBtnBox {
			margin-left: 76px;
			margin-top: 48px;
			display: flex;
			width: 334px;
			justify-content: space-between;
			.appleShop {
				width: 149px;
				height: 50px;
				cursor: pointer;
				background-image: url(../../asserts/images/applestore.png);
				background-size: 100% 100%;
				background-repeat: no-repeat;
			}
			.googleShop {
				width: 149px;
				height: 50px;
				cursor: pointer;
				background-image: url(../../asserts/images/googlestore.png);
				background-size: 100% 100%;
				background-repeat: no-repeat;
			}
		}
	}
	.shopCardImg {
		width: 380px;
		height: 514px;
		margin-top: 96px;
		margin-left: 108px;
		margin-right: 112px;
		background-image: url(../../asserts/images/shopPhone.png);
		background-size: contain;
		background-repeat: no-repeat;
	}
}
@media screen and (max-width: 991px) {
	.shopCard {
		width: 100%;
		background-color: #5bb5a2;
		border-radius: 20px 20px 20px 20px;
		display: block;
		margin: 0 auto;
		// justify-content: space-evenly;
		.shopCardDesc {
			width: 100%;
			margin: 0 auto;
			color: #ffffff;
			.bankCordContentText {
				padding-left: 24px;
				padding-right: 24px;
			}
			.bankCordContentTextTitle {
				width: 100%;
				margin-top: 96 * 0.72px;
				margin-left: 0;
				font-family: DM Sans, DM Sans;
				font-weight: 500;
				font-size: 3.6rem;
				text-align: center;
				p {
					font-size: 2.5rem;
				}
			}
			.bankCordContentTextDesc {
				font-size: 1.3 * 0.72rem;
				font-weight: 500;
				margin-left: 0;
				max-width: 100%;
				text-align: center;
				font-family: Inter, Inter;
			}
			.bankCordContentTextTag {
				width: 100%;
				margin-left: auto;
				margin-right: auto;
				margin-top: 32 * 0.72px;
				font-size: 0.9 * 0.72rem;
				.bankCordContentTextTagItem {
					width: 100%;
					display: flex;
					margin-bottom: 0.5 * 0.72rem;
					// justify-content: space-between;
					align-items: center;
					justify-content: center;
					s .tagLeft {
						width: 40%;
						display: flex;
						align-items: center;
						gap: 8 * 0.72px;
						.tagIcon {
							width: 1.5 * 0.72rem;
							height: 1.5 * 0.72rem;
							background-image: url('../../asserts/images/hook.png');
							background-size: 100% 100%;
						}
					}
					.tagRight {
						width: 40%;
						display: flex;
						gap: 8 * 0.72px;
						align-items: center;
						.tagIcon {
							width: 1.5 * 0.72rem;
							height: 1.5 * 0.72rem;
							background-image: url('../../asserts/images/hook.png');
							background-size: 100% 100%;
						}
					}
				}
			}
			.shopBtnBox {
				margin-left: auto;
				margin-right: auto;
				margin-top: 48 * 0.72px;
				margin-bottom: 3rem;
				display: flex;
				width: 80%;
				justify-content: space-between;
				.appleShop {
					width: 149 * 0.72px;
					height: 50 * 0.72px;
					background-image: url(../../asserts/images/applestore.png);
					background-size: 100% 100%;
					background-repeat: no-repeat;
				}
				.googleShop {
					width: 149 * 0.72px;
					height: 50 * 0.72px;
					background-image: url(../../asserts/images/googlestore.png);
					background-size: 100% 100%;
					background-repeat: no-repeat;
				}
			}
		}
		.shopCardImg {
			width: 380 * 0.72px;
			height: 514 * 0.72px;
			margin-top: 96 * 0.72px;
			margin-left: 108 * 0.72px;
			margin-right: 112 * 0.72px;
			margin: 0 auto;
			background-image: url(../../asserts/images/shopPhone.png);
			background-size: contain;
			background-repeat: no-repeat;
		}
	}
}
