.contactUs {
	.banner-section {
		padding-top: 97px;
		padding-bottom: 97px;

		.banner-container {
			.banner-content {
				max-width: 665px;

				.banner-serveice {
					display: flex;
					justify-content: space-between;
					.banner-serveice-item {
						display: flex;
						align-items: center;
						gap: 10px;
						font-family: Inter, Inter;
						font-weight: 500;
						font-size: 14px;
						.banner-serveice-item-icon {
							width: 26px;
							height: 26px;
							background: url(../../asserts/images/contact_serveice_icon.png) no-repeat;
							background-size: contain;
						}
					}
				}
			}

			.banner-img {
				width: 232px;
			}
		}
	}

	.contact-form-container {
		margin: 80px auto;
		width: 548px;
		max-width: 100%;

		.ant-form-item .ant-form-item-label > label {
			font-weight: 500;
			font-family: DM Sans, DM Sans;
			line-height: 22px;
		}

		.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
			background-color: #449e8b;
		}
	}
}
@media screen and (max-width: 991px) {
	.contactUs {
		.banner-section {
			.banner-container {
				.banner-content {
          padding-left: 2.5rem;
          padding-right: 2.5rem;
					.banner-serveice {
            flex-direction: column;
            gap: 10px;
            width: max-content;
            margin: auto;
					}
				}
			}
		}
	}
}
