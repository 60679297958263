body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu,
		Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;

	font-family: Inter, sans-serif;
	color: #1a191e;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

section {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 1200px;
}

.container .full {
	width: 100%;
}

.fadeIn {
	animation: 2.5s ease 0s 1 normal none running fadeIn;
}
.in{
  animation: in 0.9s ease none;
			transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
				rotateZ(0deg) skew(0deg, 0deg) opacity(0);
			transform-style: preserve-3d;
      opacity: 1 !important;
}
@keyframes fadeIn {
	0% {
		transform: translate3d(0px, 100%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
			rotateZ(0deg) skew(0deg, 0deg);
		opacity: 0;
	}
	100% {
		transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
			skew(0deg, 0deg);
		opacity: 1;
	}
}
@keyframes in {
	from {
		opacity: 0;
		transform: translateY(100%);
	}
	to {
		transform: translateY(0%);
		opacity: 1;
	}
}
