.fiatCurrencyExchange {
	.slide-change-img {
		.container-default {
			width: 100%;
			max-width: 75rem;
			margin-left: auto;
			margin-right: auto;
			.content {
				grid-column-gap: 6rem;
				grid-row-gap: 6rem;
				grid-template-rows: auto;
				grid-template-columns: 1fr 1fr;
				grid-auto-columns: 1fr;
				align-items: stretch;
				display: grid;
				// padding-top: 160px;
				.content-left {
					grid-column-gap: 30vh;
					grid-row-gap: 30vh;
					flex-direction: column;
					align-items: flex-start;
					padding-top: 25vh;
					padding-bottom: 33vh;
					display: flex;
					.content-left-iterm {
						height: 764px;
						display: flex;
						align-items: center;
						flex-direction: column;
						justify-content: center;
						.item-title {
							font-family: DM Sans, DM Sans;
							font-weight: 500;
							font-size: 28px;
							line-height: 34px;
							margin-bottom: 32px;
						}
						.item-desc {
							font-family: Inter, Inter;
							font-weight: 500;
							font-size: 18px;
							color: #666666;
							line-height: 32px;
						}
					}
				}
				.content-right {
					position: relative;
					.content-right-inner {
						flex-direction: column;
						justify-content: center;
						align-items: center;
						height: 100vh;
						display: flex;
						position: -webkit-sticky;
						position: sticky;
						top: 0;
						.img-container {
							border: 10px solid #000;
							border-radius: 50px;
							align-items: flex-start;
							width: 360px;
							// height: 800px;
							display: flex;
							overflow: hidden;
							transform: translate(0);
							transition: transform 0.5s ease;
							img {
								max-width: 100%;
								display: inline-block;
								box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
								// &::not(:nth-child(1)) {
								// 	position: absolute;
								// 	top: 0%;
								// 	bottom: 0%;
								// 	left: 100%;
								// 	right: auto;
								// }
							}
						}
					}
				}
			}
		}
	}
	.slide-change-img.slide-H5 {
		display: none;
	}
}
@media screen and (max-width: 991px) {
	.fiatCurrencyExchange {
		.slide-change-img.slide-pc {
			display: none;
		}
		.slide-change-img.slide-H5 {
			display: block;
			.page-padding {
				.container-default {
					.content {
						display: flex;
						.content-left {
							text-align: center;
							grid-column-gap: 96px;
							grid-row-gap: 96px;
							padding-top: 64px;
							padding-bottom: 64px;
							.content-left-iterm {
								height: auto;
							}
						}
					}
				}
			}
			.h5-left-img {
				border: 6px solid #000;
				border-radius: 30px;
				width: 250px;
				margin: 32px auto;
				img {
					box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
					max-width: 100%;
					display: inline-block;
					vertical-align: middle;
				}
			}
		}
	}
}
