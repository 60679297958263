.header {
	width: 100%;
	height: 89px;
	.header-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		width: 100%;
		height: 89px;
		top: 0;
		left: 0;
		bottom: auto;
		z-index: 9999;
		section.headbar {
			width: 100%;
			display: flex;
			justify-content: center;
			background-color: #fff;
			ul,
			li {
				list-style: none;
			}
			.nav {
				background-color: #fff;
				width: 100%;
				max-width: 87.5rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-top: 1.5rem;
				padding-bottom: 1.5rem;
				color: #222222;
				.navbar-left {
					height: 50px;
					img {
						height: 100%;
						object-fit: contain;
					}
				}
				.navbar-right {
					grid-column-gap: 1rem;
					grid-row-gap: 1rem;
					justify-content: center;
					align-items: center;
					display: flex;
					a {
						background-color: #5bb5a2;
						color: #fff;
						font-weight: 500;
						padding: 0.5rem 1rem;
						font-size: 1rem;
						border-radius: 6px;
						&:hover {
							background-color: #449e8b;
						}
					}
					.langImg {
						width: 26px;
						height: 26px;
						position: relative;
						cursor: pointer;
						img {
							width: 100%;
							height: 100%;
							object-fit: contain;
						}
						.langSelect {
							min-width: 10rem;
							padding: 0.5rem 0;
							margin: 0.125rem 0 0;
							background-color: #fff;
							background-clip: padding-box;
							border: 1px solid rgba(0, 0, 0, 0.15);
							border-radius: 0.25rem;
							position: absolute;
							transform: translate3d(-136px, 25px, 0px);
							top: 0px;
							left: 0px;
							right: auto;
							bottom: auto;
							will-change: transform;
							display: none;
							li {
								padding: 4px 24px;
								&:hover {
									background-color: #e9ecef;
								}
							}
							&.show {
								display: block;
							}
						}
					}
				}
				.navbar-center {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					.navbar-menu {
						grid-column-gap: 1rem;
						grid-row-gap: 1rem;
						justify-content: center;
						align-items: center;
						display: flex;
						.nav-item {
							padding: 0.5rem 1rem;
							font-size: 1rem;
							border-radius: 0.3rem;
							display: flex;
							align-items: center;
							position: relative;
							&:hover {
								cursor: pointer;
								background-color: #f8f8f8;
							}
							&::after {
								display: block;
								width: 15px;
								height: 15px;
								background-image: url('../../asserts/images/downArrow.svg');
								background-repeat: no-repeat;
								background-size: contain;
								content: '';
								margin-left: 0.5rem;
							}
							.navbar-dropdown {
								z-index: 9999;
								background-color: #fff;
								width: 100vw;
								padding-top: 1.5rem;
								opacity: 0;
								animation: fadeIn 0.7s ease forwards;
								display: none;
								position: fixed;
								top: 65px;
								bottom: auto;
								left: 0;
								right: 0;
								.navbar-dropdown-inner {
									// background-color: #f8f8f8;
									margin: 0 auto;
									display: flex;
									justify-content: center;
									.inner-sub-list {
										width: 100%;
										max-width: 87.5rem;
										padding: 2rem 0;
										display: flex;
										grid-column-gap: 2rem;
										grid-row-gap: 2rem;
										grid-row-gap: 28px;
										grid-template-rows: auto;
										grid-template-columns: auto auto auto;
										grid-auto-columns: 1fr;
										flex-direction: column;
										.navbar-dropdown-item {
											letter-spacing: -0.75px;
											font-family: DM Sans, sans-serif;
											font-size: 1.25rem;
											font-weight: 500;
											line-height: 130%;
											font-size: 18px;
											text-decoration: none;
											color: #707070;
											font-weight: 700;
											cursor: pointer;
											&:hover {
												color: #449e8b;
											}
										}
										.sub-list-title {
											font-weight: 400;
											&:hover {
												cursor: default;
												color: #707070;
											}
										}
									}
								}
							}
						}

						.nav-item.aboutUs:hover {
							.aboutUs-dropdown {
								display: block;
								opacity: 1;
							}
						}
						.nav-item.support:hover {
							.support-dropdown {
								display: block;
								opacity: 1;
							}
						}
						.nav-item.market:hover {
							.market-dropdown {
								display: block;
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
	.navbar-overlay {
		z-index: 9998;
		opacity: 0;
		animation: fadeIn 0.7s ease forwards;
		background-color: rgba(26, 25, 30, 0.6);
		padding: 0;
		display: none;
		position: fixed;
		top: 0%;
		bottom: 0%;
		left: 0%;
		right: 0%;
	}
	.navbar-right-mobile {
		display: none;
	}
	.mobile-menu {
		display: none;
	}
	.navbar-overlay.is-visible {
		display: block;
		opacity: 1;
	}
}
/* 定义关键帧动画 */
@keyframes fadeIn {
	from {
		opacity: 0; /* 动画开始时，元素完全透明 */
	}
	to {
		opacity: 1; /* 动画结束时，元素完全不透明 */
	}
}
@media screen and (max-width: 991px) {
	.header {
		.header-wrapper {
			height: auto;
			section.headbar {
				background-color: transparent;

				.nav {
					.navbar-left {
						height: 22px;
					}
					.navbar-center {
						display: none;
					}
					.navbar-right {
						display: none;
					}
					.navbar-right-mobile {
						display: flex;
						gap: 16px;
						align-items: center;
						.mobile-menu-toggle {
							cursor: pointer;
							width: 30px;
							height: 20px;
							position: relative;
							.mobile-menu-toggle-line {
								transform-origin: 100%;
								background-color: #000;
								width: 30px;
								height: 1px;
								padding-bottom: 0;
								padding-right: 0;
								position: absolute;
								right: 0;
								transition: all 0.5s ease;
							}
							.mobile-menu-toggle-line._01 {
								top: 0;
								transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
									rotateZ(0deg) skew(0deg, 0deg);
								transform-style: preserve-3d;
							}
							.mobile-menu-toggle-line._02 {
								top: 50%;
							}
							.mobile-menu-toggle-line._03 {
								bottom: 0;
								transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
									rotateZ(0deg) skew(0deg, 0deg);
								transform-style: preserve-3d;
							}
						}
						.mobile-menu-toggle.is-toggle {
							.mobile-menu-toggle-line._01 {
								transition-delay: 0.2s;
								transform: rotateZ(-39deg);
							}
							.mobile-menu-toggle-line._02 {
								width: 0;
							}
							.mobile-menu-toggle-line._03 {
								transition-delay: 0.2s;
								transform: rotateZ(39deg);
							}
						}
					}
				}
				.mobile-menu {
					display: block;
					overflow: hidden;
					z-index: 5;
					width: 100vw;
					.mobile-menu-nav {
						padding-bottom: 0.5rem;
						background-color: #fff;
						transform: translate3d(0px, -105%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
							rotateZ(0deg) skew(0deg, 0deg);
						transform-style: preserve-3d;
						transition: transform 0.3s ease;
						& > a:nth-last-child(1) {
							.mobile-dropdown-item {
								border-bottom: none;
							}
						}
						.mobile-dropdown-item {
							.menu--text {
								border-bottom: #e8e8e8 solid 1px;
								padding-top: 0.75rem;
								padding-bottom: 0.75rem;
								display: flex;
								align-items: center;
								gap: 8px;
								.icon {
									width: 18px;
									height: 18px;
									img {
										width: 100%;
										height: 100%;
										object-fit: contain;
										transition: transform 0.3s ease-in-out;
									}
									&.open {
										transform: rotate(-180deg);
									}
								}
							}
							.mobile-dropdown-sub {
								overflow: hidden;
								transition: max-height 0.3s ease-out;
								max-height: 0;
								&.open {
									transition: max-height 0.3s ease-in;
								}
								.navbar-dropdown-item {
									padding: 8px 0;
								}
							}
						}
					}
				}
				.mobile-menu.is-visible {
					display: block;
					.mobile-menu-nav {
						transform: translate3d(0px, -1px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
							rotateZ(0deg) skew(0deg, 0deg);
						transform-style: preserve-3d;
						opacity: 1;
					}
				}
				.navbar-overlay {
					animation: fadeIn 0.3s ease forwards;
				}
			}
		}
	}
}
